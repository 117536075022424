import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  main: {
    background: "#F7F7FF",
    padding: "64px 100px",
    marginBottom: "0px",
    [theme.breakpoints.down("md")]: {
      padding: "24px",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "20px",
    },
  },
  careersContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "6px",
  },
  heading: {
    textDecoration: "none",
    color: "#000",
    fontSize: "14px",
    marginBottom: "8px",
    FontFamily: "Urbanist",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      textAlign: "center",
      width: "100%",
      marginTop: "24px",
    },
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "15px",
    fontFamily: "Urbanist",
  },
  BottonTextContaienr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#05021C",
    padding: "30px",
    "& br": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
      gap: "10px",
      marginTop: "24px",
    },
    [theme.breakpoints.only("xs")]: {
      "& br": {
        display: "block",
      },
    },
  },
  elements: {
    color: "#000",
    margin: "6px 0px",
    FontFamily: "Urbanist",
    fontSize: "12px",
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
  },
  listContainer: {
    width: "16.6%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  listsContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      textAlign: "center",
      gap: "25px",
    },
  },
  copyright: {
    color: "#FFF",
    fontWeight: "100",
    fontFamily: "Urbanist",
    [theme.breakpoints.down("md")]: {
      marginBottom: "24px",
    },
  },
}));

const services = ["eModules", "Our Projects", "Case Studies"];

const about = [
  {
    name: "Our Story",
    link: "/about",
  },
  {
    name: "Our Values",
    link: "/about",
  },
];

const careers = ["Job Openings", "Blogs", "Our Presence"];

const Footer = () => {
  const { classes } = useStyles();
  return (
    <Box>
      <Box className={classes.main}>
        <Box className={classes.container}>
          <Box className={classes.logoContainer}>
            <Box
              sx={{ display: { xs: "none", lg: "block" }, marginRight: "20px" }}
            >
              <img src="./Home/Website Logo.svg" alt="Logo" width={250} />
            </Box>
            <Box
              sx={{ display: { xs: "block", lg: "none" }, marginRight: "20px" }}
            >
              <img src="./Home/Website Logo.svg" alt="Logo" width={200} />
            </Box>
          </Box>

          <Box className={classes.listContainer}>
            <Link
              className={classes.heading}
              style={{ fontFamily: "Urbanist" }}
              to="/services"
              onClick={() => window.scrollTo(0, 0)}
            >
              Services
            </Link>
            {services.map((service) => (
              <Typography
                key={service}
                sx={{ fontFamily: "Urbanist" }}
                className={classes.elements}
              >
                {service}
              </Typography>
            ))}
          </Box>
          <Box className={classes.listContainer}>
            <Link
              to="/about"
              onClick={() => window.scrollTo(0, 0)}
              className={classes.heading}
            >
              About
            </Link>
            <Typography
              component={Link}
              onClick={() => window.open("./VTDigitalBrochure.pdf", "_blank")}
              className={classes.elements}
              sx={{ fontFamily: "Urbanist" }}
            >
              Brochure
            </Typography>
            {about.map((each, index) => (
              <Typography
                component={Link}
                to={each.link}
                key={index}
                className={classes.elements}
                sx={{ fontFamily: "Urbanist" }}
              >
                {each.name}
              </Typography>
            ))}
          </Box>
          <Box className={classes.listContainer}>
            <Link
              className={classes.heading}
              style={{ fontFamily: "Urbanist" }}
              to="/careers"
              onClick={() => window.scrollTo(0, 0)}
            >
              Blogs & Articles
            </Link>

            {careers.map((each, index) => (
              <Typography
                key={index}
                className={classes.elements}
                sx={{ fontFamily: "Urbanist" }}
              >
                {each}
              </Typography>
            ))}
          </Box>

          <Box className={classes.iconsContainer}>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <FacebookSharpIcon sx={{ color: "#0069EC", fontSize: "30px" }} />
              <LinkedInIcon sx={{ color: "#0069EC", fontSize: "30px" }} />
              <TwitterIcon sx={{ color: "#0069EC", fontSize: "30px" }} />
              <InstagramIcon sx={{ color: "#0069EC", fontSize: "30px" }} />
            </Box>
            <Typography
              sx={{
                color: "#000",
                fontWeight: "100",
                fontFamily: "Urbanist",
              }}
            >
              @ Vassutech 2023
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.BottonTextContaienr}>
        <Typography className={classes.copyright}>
          Copyright © 2023. VassuTech.
        </Typography>
        <Typography
          sx={{ color: "#fff", margin: "0px 30px", fontFamily: "Urbanist" }}
        >
          Terms & Conditions <br /> Privacy Policy
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
