import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import OurMission from "./Elements/OurMission";
import WhatWeOffer from "./Elements/WhatWeOffer";
import BeaingPartner from "./Elements/BeingPartner";
import Innovations from "./Elements/Innovations.js";
import CaseStudies from "./Elements/CaseStudies";
import OurProcess from "./Elements/OurProcess";
import OurPartners from "./Elements/OutPartners";
import JoinOurTeam from "./Elements/JoinOurTeam";
import LetsGetInTouch from "../ReusableComponents/LetsGetInTouch";
import ESignature from "./Elements/ESignature";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { Link } from "react-router-dom";
import "../Styles/index.css";
import OurBlogsAndArticles from "./Elements/OurBlogsAndArticles";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import { useRef } from "react";
import Banner from "../ReusableComponents/Banner.jsx";

const useStyles = makeStyles()((theme) => ({
  main: {
    [theme.breakpoints.up("md")]: {
      marginTop: "70px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  titleDescription: {
    width: "60%",
    marginTop: "40px",
    color: "#151942",
    textAlign: "center",
    fontFamily: "Urbanist",
    fontWeight: "400",
    fontSize: "18px",
    [theme.breakpoints.down("lg")]: {
      width: "80%",
      marginTop: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "80%",
      marginTop: "24px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  atVassutech: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "64px",
  },
  atVassutechText: {
    width: "60%",
    color: "#0F143B",
    fontFamily: "Urbanist",
    fontSize: "25px",
    fontWeight: "500",
    textAlign: "center",
    marginBottom: "40px",
    transition: "1s ease-out",
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "0px 24px",
      fontSize: "18px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      padding: "0px 40px",
    },
  },
  mobileBanner: {
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  mobileBannerText: {
    color: "#000000",
  },
}));

const settings = {
  arrows: false,
  dots: false,
  className: "center",
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 5000,
  cssEase: "linear",
  useCSS: "true",
};

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [txtBanner, setTxtBanner] = useState([]);

  useEffect(() => {
    fetch("data/banner.json")
      .then((r) => r.json())
      .then((data) => setTxtBanner(data));
  }, []);

  const { classes } = useStyles();

  const slider = useRef(null);

  return (
    <Box className={classes.main}>
      <Box className={classes.title}>
        <div className="container">
          <h1 className="mainHeading">
            Digital Services Revolutionizing the Way You Connect, Collaborate
            and Succeed
          </h1>
        </div>

        <Typography className={classes.titleDescription}>
          Leverage Digital Technologies, Processes and Capabilities in order to
          enhance Business Operations, Drive Innovation to improve Customer
          Experience and Efficiency
        </Typography>

        <Link to="/about" className="howWeWork" style={{ marginTop: "24px" }}>
          How We Work
          <CallMadeIcon sx={{ fontSize: "15px", marginLeft: "5px" }} />
        </Link>
      </Box>
      <Box>
        <Box>
          <Slider ref={slider} {...settings} style={{ height: "100%" }}>
            {txtBanner.map((eachBanner, index) => (
              <Banner
                key={index}
                background={eachBanner.background}
                text={eachBanner.txt}
                imageMobile={eachBanner.bannerImageMobile}
                image={eachBanner.bannerImage}
                leftBackground="linear-gradient(135deg, #000 0%, #002057 100%)"
              />
            ))}
          </Slider>
        </Box>
      </Box>

      <Box className={classes.atVassutech}>
        <Typography className={classes.atVassutechText}>
          At{" "}
          <span style={{ fontWeight: "bold", color: "#0069EC" }}>
            VassuTech
          </span>{" "}
          , we believe that each client is unique with distinct business
          requirements and our success is measured by our clients success
        </Typography>
      </Box>

      <OurMission />
      <WhatWeOffer />
      <BeaingPartner />
      <Innovations />
      <ESignature />
      <CaseStudies />
      <OurProcess />
      <OurPartners />
      <JoinOurTeam />
      <OurBlogsAndArticles />
      <LetsGetInTouch />
    </Box>
  );
};

export default Home;
