import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";
import OurStoryText from "./OurStoryText";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 100px",
    [theme.breakpoints.only("xs")]: {
      padding: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexDirection: "column",
      marginBottom: "20px",
    },
  },
  historiesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    marginTop: "35px",
    [theme.breakpoints.only("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "70%",
    },
  },
  imageContainer: {
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const OurStory = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#FFCA49" />
        <Title text="Our Story" color="#05021C" align="center" />
      </Box>
      <Box className={classes.historiesContainer}>
        <Box className={classes.historyContainer}>
          <Box className={classes.imageContainer}>
            <img
              src="./About/Our Story1.svg"
              alt="history"
              style={{ width: "100%", marginTop: "10px", borderRadius: "15px" }}
            />
          </Box>
          <OurStoryText
            moveFrom={200}
            title="Initiatives"
            description="Vassutech always come up with a lot of initiatives, proactively for process improvement thus bringing value additions to our clients. Our major initiative was to establish of CoE (Centre of Excellence) in the domain of Quality Assurance and defining the approach, termed as 'Testing CICD' (Continuous Integration and Continuous Delivery/Deployment to ensure QA). Another initiative was to develop product development in addressing the challenges being faced by the Insurance Industry."
          />
        </Box>
        <Box className={classes.historyContainer}>
          <OurStoryText
            moveFrom={-200}
            title="Execution Model"
            description="The Vassutech execution model is to emphasize on Agile Methodology combining with DevOps practices thus releasing software builds in incremental fashion with continuous feedback received and responding to changes during every sprint cycle for faster delivery. Vassutech has a team working in Onsite and Offshore delivery model to cover the business time window of the client. VassuTech has established a process capable of tackling the majority of the stated issues, aiming to attain an 80% achievement of the desired outcome throughout the product's lifecycle."
          />
          <Box className={classes.imageContainer}>
            <img
              src="./About/Our Story2.svg"
              alt="history"
              style={{
                width: "100%",
                marginTop: "10px",
                borderRadius: "15px",
              }}
            />
          </Box>
        </Box>
        <Box className={classes.historyContainer}>
          <Box className={classes.imageContainer}>
            <img
              src="./About/Our Story3.svg"
              alt="history"
              style={{ width: "100%", marginTop: "10px", borderRadius: "15px" }}
            />
          </Box>
          <OurStoryText
            moveFrom={200}
            title="Milestone Achievement"
            description="VassuTech major accomplishments include delivering Claims reporting systems in record time for a top 10 insurance company in the world. VassuTech also conceptualized, architected and delivered the Enterprise Information Program for a renowned Mortgage Insurance company. There was another milestone crossed by implementing complex data migration from legacy insurance system to Guidewire policy centre and remediation of security vulnerabilities of contract underwriting system. "
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OurStory;
