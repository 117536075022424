// import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { keyDriveElements } from "../../ServiceElements/elements";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 100px",
    boxSizing: "border-box",
    [theme.breakpoints.only("xs")]: {
      padding: "18px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0px",
    },
  },
  keyDriversText: {
    width: "49%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
    fontSize: "20px",
    color: "#FFFFFF",
    fontFamily: "Urbanist",
    // [theme.breakpoints.down("lg")]: {
    //   fontSize: "14px",
    // },
    [theme.breakpoints.down("lg")]: {
      width: "90%",
      display: "flex",
      justifyContent: "flex-left",
      alignItems: "flex-start",
      paddingLeft: "40px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "24px",
      paddingLeft: "64px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "85%",
      fontSize: "16px",
      paddingLeft: "0px",
    },
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#05021C",
    borderRadius: "20px",
    paddingRight: "25px",
    overflow: "hidden",
    width: "100%",
    height: "440px",
    paddingLeft: "28px",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      paddingRight: "0px",
      padding: "24px",
      paddingBottom: "40px",
      borderRadius: "0px",
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px 10%",
    },
  },
  keyDriversContaier: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    height: "200px",
    gap: "10px",
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      flexWrap: "nowrap",
      gap: "30px",
      marginTop: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      marginLeft: "40px",
    },
  },
  imageContainer: {
    width: "80%",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
      padding: "20px 7%",
      order: "-1",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0px",
      order: "-1",
    },
  },
}));

const KeyDrivers = ({ activeKeyDriver }) => {
  const { classes } = useStyles();
  //   const activeKeyDriver = keyDriveElements[activeService];
  // const { path } = useParams();
  // const active = keyDriveElements.find((s) => `:${s.path}` === path);

  return (
    <Box className={classes.container}>
      <Box className={classes.mainContainer}>
        <Box
          sx={{
            display: { lg: "none", xs: "block" },
            order: "-2",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mt: "24px",
            }}
          >
            <Bar backgroundColor="#FFFFFF" />
            <Title text="Key Drivers" color="#FFFFFF" />
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: { lg: "block", xs: "none" } }}>
            <Bar backgroundColor="#FFFFFF" />
            <Title text="Key Drivers" color="#FFFFFF" />
          </Box>
          <Box className={classes.keyDriversContaier}>
            {activeKeyDriver.keyDrivers.map((eachItem, index) => (
              <Typography className={classes.keyDriversText} key={index}>
                <CheckCircleOutlineIcon
                  sx={{ marginTop: { xs: "0px", md: "5px" } }}
                />
                {eachItem}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box
          className={classes.imageContainer}
          sx={{ display: { lg: "block", xs: "none" } }}
        >
          <img
            src={activeKeyDriver.imagePath}
            alt="dfja"
            style={{ height: "450px", marginRight: "-25px" }}
          />
        </Box>
        <Box
          className={classes.imageContainer}
          sx={{ display: { lg: "none", xs: "block" } }}
        >
          <img
            src={activeKeyDriver.imagePath}
            alt="dfja"
            style={{ width: "100%", borderRadius: "24px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default KeyDrivers;
