import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import Face6OutlinedIcon from "@mui/icons-material/Face6Outlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";

const useStyles = makeStyles()((theme) => ({
  container: {
    background: `linear-gradient(180deg, #1E203A 0%, #2B2E54 100%)`,
    padding: "64px 100px",
    [theme.breakpoints.only("xs")]: {
      padding: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardContainer: {
    background: `linear-gradient(0deg, rgba(233, 228, 255, 0.08) 0%, rgba(233, 228, 255, 0.08) 100%), linear-gradient(180deg, rgba(19, 22, 57, 0.63) 0%, rgba(19, 22, 57, 0.00) 100%)`,
    width: "31%",
    height: "300px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "0px 40px",
    gap: "10px",
    color: "#FFFFFF",
    paddingTop: "40px",
    [theme.breakpoints.down("lg")]: {
      width: "270px",
      height: "270px",
      paddingTop: "30px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "327px",
      height: "327px",
    },
  },
  cardsContainer: {
    gap: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "20px 80px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 80px",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "18px",
      padding: "0px",
    },
  },
  cardTitle: {
    fontSize: "18px",
    fontFamily: "Urbanist",
    fontWeight: "600",
    margin: "10px 0px",
    [theme.breakpoints.only("sm")]: {
      fontSize: "20px",
    },
  },
  cardDescription: {
    fontSize: "14px",
    fontFamily: "Urbanist",
    color: "#E9E4FF",
    lineHeight: "18px",
    [theme.breakpoints.only("sm")]: {
      fontSize: "18px",
      textAlign: "justify",
      lineHeight: "25px",
    },
  },
}));

const storyElements = [
  {
    icon: (
      <TipsAndUpdatesOutlinedIcon sx={{ fontSize: "50px", color: "#f7edc8" }} />
    ),
    title: "Innovation",
    description:
      "Vassutech emphasize adaptability and a willingness to embrace change, coming up with innovative solutions to the existing challenges.",
    //  as world is witnessing technology disruptions. Our mission is to be creative and forward-thinking, and support initiatives that lead to new ideas for process improvements and product development.",
  },
  {
    icon: <Face6OutlinedIcon sx={{ fontSize: "50px", color: "#3d7cbf" }} />,
    title: "Teamwork",
    description:
      "VassuTech fosters positive relationships with our clients to achieve mutual success. We establish effective communication by building a strong team spirit.",
    //  collaboration, empathy, exchange of ideas, cross-sectional trainings and ensure transparency for building a strong team spirit.",
  },
  {
    icon: (
      <VolunteerActivismOutlinedIcon
        sx={{ fontSize: "50px", color: "#e3b202" }}
      />
    ),
    title: "Excellence",
    description:
      "VassuTech expertise and experience in providing solutions to our customers and their wide acceptance of services proved to be testimonial of our excellence.",
    //  on Digital Platform front and Quality Assurance by dedicated team of experts. Our excellence has embarked our journey to the innovation and product development.",
  },
  {
    icon: (
      <MilitaryTechOutlinedIcon sx={{ fontSize: "50px", color: "#9e817a" }} />
    ),
    title: "Compliance",
    description:
      "VassuTech ensures any digital or innovative software solution to comply with specific standards keeping in line with latest cyber laws and other data security practices.",
    // "VassuTech ensures any digital or innovative software solution to comply with specific regulations or standards keeping in line with latest cyber laws (geographic and localized laws) and other data security practices. such as PII data protection to counter any potential threats and vulnerabilities.",
  },
  {
    icon: (
      <CreateNewFolderOutlinedIcon
        sx={{ fontSize: "50px", color: "#fcdccc" }}
      />
    ),
    title: "Culture",
    description:
      "VassuTech encompasses the shared values, beliefs, attitudes, and behaviours that shape the interactions between employees, management, and our business clients.",
    // "VassuTech encompasses the shared values, beliefs, attitudes, and behaviours that shape the interactions between employees, management, and our business clients. VassuTech promotes a collaborative atmosphere where both our business clients and teams work together towards common goals.",
  },
  {
    icon: <LocalAtmOutlinedIcon sx={{ fontSize: "50px", color: "#6b9e5a" }} />,
    title: "Growth",
    description:
      "VassuTech geographical service is spread across globe and its offices are located in Canada, USA and India.",
    // "VassuTech’s journey started with Data Management solutions and grown into developing innovative software digital products or services that address customer needs by embracing digital transformation technologies. VassuTech geographical service is spread across globe and its offices are located in Canada, USA and India",
  },
];
const storyElementsMd = [
  {
    icon: (
      <TipsAndUpdatesOutlinedIcon sx={{ fontSize: "40px", color: "#f7edc8" }} />
    ),
    title: "Innovation",
    description:
      "Generate creative ideas for IT solutions. Brainstorming sessions, collaboration, and gathering feedback from stakeholders",
  },
  {
    icon: <Face6OutlinedIcon sx={{ fontSize: "40px", color: "#3d7cbf" }} />,
    title: "Teamwork",
    description:
      "Long established fact that a readeed to will be distracted by the readable content of a page when looking at its layout",
  },
  {
    icon: (
      <VolunteerActivismOutlinedIcon
        sx={{ fontSize: "40px", color: "#e3b202" }}
      />
    ),
    title: "Positivity",
    description:
      "Long established fact that a readeed to will be distracted by the readable content of a page when looking at its layout",
  },
  {
    icon: (
      <MilitaryTechOutlinedIcon sx={{ fontSize: "40px", color: "#9e817a" }} />
    ),
    title: "Excellence",
    description:
      "Long established fact that a readeed to will be distracted by the readable content of a page when looking at its layout",
  },
  {
    icon: (
      <CreateNewFolderOutlinedIcon
        sx={{ fontSize: "40px", color: "#fcdccc" }}
      />
    ),
    title: "Culture",
    description:
      "Long established fact that a readeed to will be distracted by the readable content of a page when looking at its layout",
  },
  {
    icon: <LocalAtmOutlinedIcon sx={{ fontSize: "40px", color: "#6b9e5a" }} />,
    title: "Growth",
    description:
      "Long established fact that a readeed to will be distracted by the readable content of a page when looking at its layout",
  },
];

const OurValues = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} id="story">
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#FFFFFF" />
        <Title text="Our Values" color="#FFFFFF" align="center" />
      </Box>
      <Box className={classes.cardsContainer}>
        {storyElements.map((each, index) => (
          <Box
            key={index}
            className={classes.cardContainer}
            sx={{ display: { md: "none", lg: "block", xs: "none" } }}
          >
            <Typography>{each.icon}</Typography>
            <Typography className={classes.cardTitle}>{each.title}</Typography>
            <Typography className={classes.cardDescription}>
              {each.description}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box className={classes.cardsContainer}>
        {storyElementsMd.map((each, index) => (
          <Box
            key={index}
            className={classes.cardContainer}
            sx={{ display: { md: "block", lg: "none", xs: "block" } }}
          >
            <Typography>{each.icon}</Typography>
            <Typography className={classes.cardTitle}>{each.title}</Typography>
            <Typography className={classes.cardDescription}>
              {each.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OurValues;
