export const keyDriveElements = {
  imagePath: "./Service/KeyDrives/Digital Solutions Keydrive.svg",
  keyDrivers: [
    "Global Reach",
    "Productivity and Efficiency",
    "Business Process Optimization",
    "Collaboration and Communication",
    "Cost Savings",
    "Competitive Advantage",
  ],
  path: "digital-solution",
};

export const benefits = {
  benefitElements: [
    "Improved Customer Experience",
    "Faster Time to Market",
    "Cost Savings",
    "Access to Global Markets",
    "Competitive Advantage",
    "Business process Automation",
  ],
  image: "./Service/Benefits/Digital Solution Benefit.svg",
};

export const content = {
  image: "./Service/Banners/digtal banner.jpg",
  text: "Simple & Smart Solution with Splendid Result ",
  mainHeading: "Embracing the future with the New age Digital Solutions",
  textBackground: "linear-gradient(180deg, #5956E9 0%, #F7F7FF 100%)",
  leftBackground: "#000",
};
export const about = {
  firstAbout: {
    serviceName: "Digital Solutions",
    description: `Revolutionize how businesses and individuals operate, communicate, and interact in today's digital age.
        Digital solutions are crucial for organizations aiming to stay relevant, competitive, and responsive in today's dynamic and technology-driven business landscape. They empower businesses with enhanced capabilities, enabling them to meet customer expectations, drive innovation, and achieve long-term success.`,
  },
  secondAbout: {
    serviceName: "Capabilities",
    description: ` Real time digital applications are the backbone of many businesses. At VassuTech,  we are at the forefront of building applications that are scalable, reliable, and user-friendly. Our team has expertise in the latest technologies, which enables us to build high-quality digital applications that are responsive and dynamic for business users and consumers, in compliance with data security practices.`,
  },
};
