import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CallMadeIcon from "@mui/icons-material/CallMade";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";
import AppsIcon from "@mui/icons-material/Apps";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import PsychologyIcon from "@mui/icons-material/Psychology";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import StorageIcon from "@mui/icons-material/Storage";
import LockIcon from "@mui/icons-material/Lock";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { Link } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: "64px",
    background: "#F3F6FF",
    padding: "80px 60px",
    paddingBottom: "64px",
    [theme.breakpoints.down("lg")]: {
      paddingBottom: "40px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px",
      marginTop: "0px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px",
      marginTop: "0px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "50px",
    padding: "0px 35px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginLeft: "0px",
      width: "100%",
      padding: "0px",
    },
    [theme.breakpoints.only("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginLeft: "0px",
      width: "100%",
      padding: "0px",
    },
  },

  exploreButton: {
    background: "linear-gradient(180deg, #0069EC 0%, #3986E5 100%)",
    color: "#fff",
    fontFamily: "Urbanist",
    textTransform: "none",
    borderRadius: "30px",
    padding: "12px 24px",
    "&: hover": {
      background: "linear-gradient(180deg, #0069EC 0%, #3986E5 100%)",
    },
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "24px",
    width: "200px",
    marginRight: "95px",

    [theme.breakpoints.only("md")]: {
      marginRight: "95px",
      width: "200px",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
    },
  },
  topContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
  },
  description: {
    color: "#808080",
    fontFamily: "Urbanist",
    fontSize: "20px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginLeft: "0px",
      padding: "0px 20px",
      fontSize: "18px",
      width: "100%",
      marginBottom: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "22px",
    },
  },
  servicesContainer: {
    gap: "8px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "20px 80px",
    marginTop: "16px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 40px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "20px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "20px",
    },
  },
  serviceContainer: {
    background: "#fff",
    borderRadius: "20px",
    width: "23%",
    height: "270px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: "8px",
    cursor: "pointer",
    padding: "54px 30px",
    paddingTop: "60px",
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      width: "31%",
      padding: "25px",
      paddingTop: "55px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      paddingTop: "16px",
      height: "327px",
      width: "327px",
    },
    [theme.breakpoints.only("md")]: {
      marginLeft: "20px",
      paddingTop: "16px",
      height: "327px",
      width: "327px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "16px",
      height: "327px",
      width: "327px",
      marginLeft: "0px",
      marginBottom: "16px",
    },
    "&:hover": {
      background: `linear-gradient(29deg, #304FFE 0%, #3D93FF 100%)`,
      boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.24)`,
    },
  },
  icons: {
    [theme.breakpoints.down("md")]: {
      fontSize: "80px",
    },
  },
  card: {
    boxSizing: "border-box",
    background: "#fff",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    padding: "32px 28px",
    height: "320px",
    marginRight: "20px",
    [theme.breakpoints.down("lg")]: {
      padding: "24px 28px",
      height: "300px",
      maxWidth: "300px",
    },
  },
}));

const listOfServices = [
  {
    logoLg: <AppsIcon sx={{ fontSize: "64px" }} />,
    title: "Enterprise Application Integration",
    path: "/services/application-integration",
  },
  {
    logoLg: <ViewInArIcon sx={{ fontSize: "64px" }} />,
    title: `Blockchain Technology`,
    path: "/services/blockchain-solutions",
  },
  {
    logoLg: <PsychologyIcon sx={{ fontSize: "64px" }} />,
    title: `Product Development`,
    path: "/services/digital-solutions",
  },
  {
    logoLg: <CloudDoneIcon sx={{ fontSize: "64px" }} />,
    title: "Cloud Computing and Consulting",
    path: "/services/data-management",
  },
  {
    logoLg: <StorageIcon sx={{ fontSize: "64px" }} />,
    title: "Enterprse Data Management",
    path: "/services/data-management",
  },
  {
    logoLg: <LockIcon sx={{ fontSize: "64px" }} />,
    title: "Data Security",
    path: "/services/data-security",
  },
  {
    logoLg: <LibraryAddCheckIcon sx={{ fontSize: "64px" }} />,
    title: "Quality Assurance (CoE)",
    path: "/serivices/quality-assurance",
  },
  {
    logoLg: <AnalyticsIcon sx={{ fontSize: "64px" }} />,
    title: "Business Analytics (BI & AI/ML)",
    path: "/services/bi-ai-ml",
  },
];

const WhatWeOffer = () => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [hover, setHover] = useState(null);

  const handleBoxClick = (index) => {
    setSelectedBox();
  };

  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Box className={classes.topContainer}>
          <Bar backgroundColor="#3986E5" />
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Title text="What We Offer ?" color="#022B69" />
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <Title text="What We Offer ?" color="#022B69" align="center" />
          </Box>
          <Typography className={classes.description}>
            VassuTech offers breadth of services across the IT Landscape of an
            Enterprise Business
          </Typography>
        </Box>
        <Box>
          <Button
            component={Link}
            to="/services/application-integration"
            disableTouchRipple
            onClick={() => window.scrollTo(0, 0)}
            className={classes.exploreButton}
          >
            More Services
            <CallMadeIcon sx={{ fontSize: "15px", marginLeft: "5px" }} />
          </Button>
        </Box>
      </Box>

      <Box>
        <Box className={classes.servicesContainer}>
          {listOfServices.map((services, index) => (
            <Box
              component={Link}
              to={services.path}
              key={index}
              className={classes.serviceContainer}
              onClick={() => window.scrollTo(0, 0)}
              sx={{
                background: selectedBox ? "#304FFE" : "#fff",
              }}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(null)}
            >
              <Typography
                sx={{
                  color:
                    hover === index || selectedBox === index
                      ? "#fff"
                      : "#0069EC",
                  marginBottom: "10px",
                }}
              >
                {services.logoLg}
              </Typography>
              <Typography
                className={classes.servecesTitle}
                style={{
                  color:
                    hover === index || selectedBox === index
                      ? "#fff"
                      : "#000000",
                }}
              >
                {services.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default WhatWeOffer;
