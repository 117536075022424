import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";

const useStyles = makeStyles()((theme) => ({
  constainer: {
    padding: "64px 24px",
    [theme.breakpoints.down("lg")]: {
      padding: "64px 100px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "24px",
    },
  },
  imageContainerLeft: {
    width: "50%",
    background: `url("./Home/Case Study 2.svg")`,
    backgroundSize: "cover",
    height: "470px",
    [theme.breakpoints.down("lg")]: {
      width: "360px",
      height: "360px",
    },
    [theme.breakpoints.down("md")]: {
      width: "300px",
      height: "300px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "480px",
      height: "480px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  imageContainerRight: {
    width: "50%",
    background: `url("./Home/Case Study 1.svg")`,
    backgroundSize: "cover",
    height: "470px",
    [theme.breakpoints.down("lg")]: {
      width: "360px",
      height: "360px",
    },
    [theme.breakpoints.down("md")]: {
      width: "330px",
      height: "300px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "480px",
      height: "480px",
    },
  },
  boxesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  boxesSubContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    marginBottom: "24px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  textContainerLeft: {
    background: "#fcfcfc",
    width: "50%",
    height: "470px",
    padding: "80px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "16px",
    [theme.breakpoints.down("lg")]: {
      width: "360px",
      height: "360px",
      padding: "30px",
    },
    [theme.breakpoints.down("md")]: {
      width: "300px",
      height: "300px",
      padding: "20px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "480px",
      height: "480px",
      padding: "40px",
    },
  },
  title: {
    color: "#05021C",
    fontSize: "26px",
    fontWeight: "600",
    fontFamily: "Urbanist",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      width: "100%",
      fontSize: "18px",
    },
    [theme.breakpoints.only("sm")]: {
      fontsize: "26px",
      textAlign: "center",
      width: "100%",
    },
  },
  description: {
    fontSize: "18px",
    fontFamily: "Urbanist",
    color: "#333333",
    textAlign: "justify",
    marginTop: "8px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      marginTop: "0px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "18px",
      marginTop: "0px",
      lineHeight: "40px",
    },
  },
}));

const CaseStudies = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.constainer}>
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#13135F" />
        <Title color="#13135F" text="Case Studies" align="center" />
      </Box>
      <Box className={classes.boxesContainer}>
        <Box className={classes.boxesSubContainer}>
          <Box className={classes.textContainerLeft}>
            <Typography className={classes.title}>
              Enterprise Data Management
            </Typography>
            <Typography className={classes.description}>
              A Financial and Mortgage company’s data was resided in silos
              across its business line and was facing challenges of data
              insights to arrive at business decisions to maximize productvity
              and revenue. A big Dataware solution was propsoed and implemented.
            </Typography>
          </Box>
          <Box
            className={classes.imageContainerRight}
            sx={{ order: { xs: "-1", md: "2" } }}
          ></Box>
        </Box>
        <Box className={classes.boxesSubContainer}>
          <Box className={classes.imageContainerLeft}></Box>
          <Box className={classes.textContainerLeft}>
            <Typography className={classes.title}>
              Product Development
            </Typography>
            <Typography className={classes.description}>
              A Real Estate company endeavors to bring the services to customers
              by improving the closing process by developing new Digital
              Platform using latest technology for better UX and bring value and
              transparency to every transaction.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseStudies;
