import { Box, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "./index.css";

const useStyles = makeStyles()((theme) => ({
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "20px 120px",
    marginBottom: "48px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  logoContainer: {
    width: "55px",
    height: "55px",
    borderRadius: "50%",
    background: "linear-gradient(to bottom, #FC4F5A, #FFCA49)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "25px",
    },
  },
  button: {
    fontFamily: "Urbanist",
    color: "#000000",
    textTransform: "none",
    fontSize: "16px",
    width: "100px",
    "&:hover": {
      background:
        "linear-gradient(180deg, #13135F 0%, #6941C6 59.90%, #E65C4F 97.40%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      background: "#E4E4E7",
      borderRadius: "30px",
      color: "#808080",
    },
  },
  activeButton: {
    fontFamily: "Urbanist",
    color: "#0069EC",
    textTransform: "none",
    fontSize: "16px",
    width: "100px",
    fontWeight: "600",
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      background: "linear-gradient(180deg, #0069EC 0%, #59A1FB 100%)",
      borderRadius: "30px",
      color: "#FFFFFF",
      "&:hover": {
        background: "linear-gradient(180deg, #0069EC 0%, #59A1FB 100%)",
        color: "#FFFFFF",
      },
    },
  },
  whiteBackgroundContainer: {
    background: "#05021C",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const buttonElements = [
  {
    button: "Application Integration",
    path: "/services/application-integration",
    icon: "./Service/Icons/Application Integration Icon.svg",
  },
  {
    button: "Blockchain Solutions",
    path: "/services/blockchain-solutions",
    icon: "./Service/Icons/Blockchain Icon.svg",
  },
  {
    button: "Digital Solutions",
    path: "/services/digital-solutions",
    icon: "./Service/Icons/Digital Solutions Icon.svg",
  },
  {
    button: "Data Management",
    path: "/services/data-management",
    icon: "./Service/Icons/Data Security Icon.svg",
  },
  {
    button: "Data Security",
    path: "/services/data-security",
    icon: "./Service/Icons/Data Management Icon.svg",
  },
  {
    button: "Quality Assurance",
    path: "/services/quality-assurance",
    icon: "./Service/Icons/Quality Assurance Icon.svg",
  },
  {
    button: "BI and AI/ML",
    path: "/services/bi-ai-ml",
    icon: "./Service/Icons/BI AI amd ML Icon.svg",
  },
  {
    button: "Cloud Solutions",
    path: "/services/cloud-solutions",
    icon: "./Service/Icons/Cloud Solution Icon.svg",
  },
];

const ButtonsComponent = () => {
  const { classes } = useStyles();

  const { pathname } = useLocation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Box className={classes.buttonsContainer}>
          {buttonElements.map((eachButton, index) => (
            <Box
              component={Link}
              to={eachButton.path}
              key={index}
              className={classes.buttonContainer}
            >
              <Box className={classes.logoContainer}>
                <Box className={classes.whiteBackgroundContainer}>
                  <img
                    src={eachButton.icon}
                    alt="icon"
                    style={{ width: "60%" }}
                  />
                </Box>
              </Box>
              <Button
                disableTouchRipple
                className={
                  pathname === eachButton.path
                    ? classes.activeButton
                    : classes.button
                }
              >
                {eachButton.button}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: { lg: "none", xs: "block", padding: "0px" },
          marginBottom: "0px",
          marginTop: "40px",
        }}
      >
        <Slider {...settings} style={{ hieght: "150px" }}>
          {buttonElements.map((eachButton, index) => (
            <Box
              component={Link}
              to={eachButton.path}
              key={index}
              className={classes.buttonContainer}
              px={1}
            >
              <Button disableTouchRipple className={classes.activeButton}>
                {eachButton.button}
              </Button>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default ButtonsComponent;
