export const keyDriveElements = {
  imagePath: "./Service/KeyDrives/Application Integration Keydrive.svg",
  keyDrivers: [
    "Seemless Data Exchange",
    "Time to access shared data",
    "Business Process Optimization",
    "Competitive Advantage",
    "Privacy",
    "Real time Information",
  ],
  path: "application-integration",
};

export const benefits = {
  benefitElements: [
    "Improved Customer Experience",
    "Easier Collaboration",
    "Faster Time to Market",
    "Efficient Data Management",
    "Access to Global Market",
    "Increased Efficiency and Cost Savings",
  ],
  image: "./Service/Benefits/Application Integratin Benefit.svg",
};

export const content = {
  image: "./Service/Banners/Application Integration Banner.svg",
  text: `Bridging Data Silos for Connecting and Handshaking`,
  mainHeading: "Achieving Business Success through Enterprise Integration",
  textBackground: "linear-gradient(180deg, #3D93FF 0%, #00BAD8 100%)",
  leftBackground: "linear-gradient(136deg, #000 0%, #003474 100%)",
};

export const about = {
  firstAbout: {
    serviceName: "Enterprise Integration",
    description: `Integrating IT applications within an organization is critical for achieving a streamlined workflow and efficient data management.
        IT application integration, also known as application integration or software integration, refers to the process of connecting and combining different software applications and systems within an organization to enable seamless data flow, communication, and interaction. The primary goal of enterprise integration is to create a unified and cohesive IT infrastructure that allows different departments and systems to work together efficiently.`,
  },
  secondAbout: {
    serviceName: "Capabilities",
    description: `VassuTech has expertise in integrating various applications, including ERP, CRM and other Guidewire applications for seamless exchange of data from upstream to downstream systems or vice-versa through middleware components, microservices and other integration tools and techniques in order to sync the business data and business process workflow`,
  },
};
