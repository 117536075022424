import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Title from "./Title";
import Bar from "./Bar";
const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "54px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  description: {
    width: "65%",
    color: "#000000",
    lineHeight: "30px",
    fontSize: "20px",
    fontFamily: "Urbanist",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "16px",
    },
  },
}));

const About = ({ title, description, width }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box mt={2}>
        <Bar backgroundColor="#05021C" />
        <Title text={title} width={width} />
      </Box>
      <Typography className={classes.description}>{description}</Typography>
    </Box>
  );
};

export default About;
