import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 100px",
    background: "#FAFAFF",
    [theme.breakpoints.down("md")]: {
      padding: "40px 24px",
      background: "#FFF",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
  },
}));

const OurPartners = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#0F143B" />
        <Title text="Our Partners" color="#05021C" align="center" />
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="./Home/Our Partners.svg"
            alt="sdlkjf"
            style={{ width: "100%", marginTop: "30px" }}
          />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <img
            src="./Home/Our Partners Mobile.jpg"
            alt="sdlkjf"
            style={{ width: "100%", marginTop: "30px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OurPartners;
