export const keyDriveElements = {
  imagePath: "./Service/KeyDrives/Digital Solutions Keydrive.svg",
  keyDrivers: [
    "Data Loss and Time to Recovery",
    "Security & Privacy",
    "Operational Costs",
    "Legal Issues",
    "Application Performance",
    "Patches & Technology Upgrade",
  ],
  path: "cloud-solutions",
};

export const benefits = {
  benefitElements: [
    "Infrastructure Cost Savings",
    "Business Continuity and Recovery",
    "Smooth Adoption of Technology",
    "Software Licensing Management",
    "Leverage Powerful Infrastructure ",
  ],
  image: "./Service/Benefits/Blockchain Benefit.svg",
};

export const content = {
  image: "./Service/Banners/Cloud Service Banner.jpg",
  text: "Effective Utilization of  Resources from One Place and on Demand ",
  mainHeading:
    "Unleashing the Potential of Cloud Computing for Businesses and Individuals",
  textBackground: "linear-gradient(180deg, #C1CBFF 0%, #3986E5 100%)",
  leftBackground: "#000",
};

export const about = {
  firstAbout: {
    serviceName: "Cloud Solution",
    description: `Cloud transformation initially embraced as an IT infra cost-streamlining initiative, to reduce TCO for enterprises but today it's no more a simple question to have certain IT/ERP practices, capabilities and infrastructure in-house or not. Today Cloud adoption is more becoming a necessity and an operational reality to stay connected to markets and ecosystems. For example to Access to secondary data and insights from ecosystem is a driver for cloud transformation. Certain cloud platforms have added advantages of faster go to market, Analytics, AI/ML and other dimensions which are not core areas of the Enterprise but can add value to increase their market share.`,
  },
  secondAbout: {
    serviceName: "Capabilities",
    description: `At VassuTech leverage our wide and deep exposure to both legacy captive information systems and data assets and evolving cloud models including private and hybrid clouds. We identify the gaps and risks in your cloud strategy and formulate a road-map for cloud transformation aligned to the business strategy. VassTech's cloud transformation approach to weigh the pros and cons of migration of certain services or data assets to the cloud mode shall consider optimizing cloud pay-offs vs. risks while adhering to compliance at the same time`,
  },
};
