import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";
import { motion } from "framer-motion";
import "../../../Styles/index.css";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 70px",
    [theme.breakpoints.only("xs")]: {
      padding: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px 10%",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardContainer: {
    width: "40%",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "20px",
    height: "220px",
    boxShadow: " 0px 1px 8px 0px rgba(0,0,0,0.1)",
    borderRadius: "10px",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      textAlign: "center",
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
    flexWrap: "wrap",
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  title: {
    fontSize: "18px",
    color: "#252F40",
    fontFamily: "Urbanist",
    fontWeight: "600",
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
  },
  subTitle: {
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Urbanist",
    color: "#3986E5",
    marginBottom: "10px",
    marginTop: "4px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "10px",
      marginBottom: "6px",
      marginTop: "3px",
    },
  },
  description: {
    fontSize: "14px",
    fontFamily: "Urbanist",
    color: "#67748E",
    [theme.breakpoints.down("lg")]: {
      fontSize: "10px",
    },
  },
  rightContainer: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const cardDetails = [
  {
    image: "./About/Staff1.jpg",
    title: "Vasundhara Kotari",
    subTitle: "Director",
    iWidth: "100px",
    description:
      "Vasundhara Kotari, Co-Founder of VassuTech, brings to the company a wealth of experience spanning over two decades in businesses led by women",
  },
  {
    image: "./About/Sridhar.jpg",
    title: "Sridhar Kotari",
    iWidth: "100px",
    subTitle: "Principle Director",
    description:
      "Sridhar, a burgeoning entrepreneur founded VassuTech which contributed in formulating and implementing IM Strategy for many an Enterprise in various verticals.",
  },
  {
    image: "./About/Sudharshan Reddy.jpg",
    title: "Sudarshan Reddy",
    subTitle: "Head of India Unit (Operations)",
    iWidth: "100px",
    description:
      "Sudarshan Reddy is a seasoned techno management professional who is responsible for building and leading high-performance offshore development teams.",
  },
  {
    image: "./About/Sanjiv Purba.jpg",
    title: "Sanjiv Purba",
    subTitle: "Strategic Adviser",
    iWidth: "60px",
    description:
      "Sanjeev Purba is an Advisor to VassuTech, Canada Unit, in running the business operations",
  },
];

const animationVariants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.08 * index,
      duration: 1,
    },
  }),
};

const MeetOurExperts = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#5956E9" />
        <Title text="Meet Our Team" color="#37393F" align="center" />
      </Box>
      <Box className={classes.cardsContainer}>
        {cardDetails.map((eachItem, index) => (
          <Box key={index} className={classes.cardContainer}>
            {/* <motion.div
              className="exprtsLeftContainer"
              variants={animationVariants}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              custom={index}
            > */}
            <img
              src={eachItem.image}
              alt={eachItem.image}
              style={{ borderRadius: "50%", width: "100px" }}
            />
            {/* </motion.div> */}
            <Box className={classes.rightContainer}>
              <Typography className={classes.title}>
                {eachItem.title}
              </Typography>
              <Typography className={classes.subTitle}>
                {eachItem.subTitle}
              </Typography>
              <Typography className={classes.description}>
                {eachItem.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MeetOurExperts;
