import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  bar: {
    width: "160px",
    height: "10px",
    marginBottom: "8px",
    [theme.breakpoints.down("md")]: {
      width: "120px",
      marginTop: "40px",
    },
  },
}));

const Bar = ({ backgroundColor }) => {
  const { classes } = useStyles();
  return <Box className={classes.bar} sx={{ background: backgroundColor }} />;
};

export default Bar;
