import { Box, Typography } from "@mui/material";
// import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";
import { makeStyles } from "tss-react/mui";
import EsignatureComponent from "./EsignatureComponent";
import Notarization from "./Notarization";
import Products from "./Products";
import { productElements } from "./ProductElemets";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 180px",
    paddingBottom: "58px",
    background:
      "linear-gradient(147deg, #070A2C 0.89%, #06052F 37.25%, #000001 99.2%)",
    [theme.breakpoints.only("xs")]: {
      padding: "24px",
      paddingBottom: "40px",
      paddingTop: "0px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      padding: "40px 0px",
      paddingBottom: "60px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  titleDescription: {
    fontSize: "40px",
    textAlign: "center",
    background: `linear-gradient(93deg, #FC4F5A 13.63%, #304FFE 32.31%, #F7B66C 49.15%, #A272AF 67.09%, #5333ED 83.93%)`,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontFamily: "Urbanist",
    fontWeight: "600",
    marginTop: "24px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
      width: "90%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "80%",
      fontSize: "32px",
    },
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "20px",
    },
  },
  description: {
    width: "60%",
    marginTop: "40px",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Urbanist",
    fontWeight: "400",
    fontSize: "18px",
    marginBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      width: "80%",
      marginTop: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "80%",
      marginTop: "24px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
}));

const Esignature = () => {
  const { classes } = useStyles();
  console.log(productElements);
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        {/* <Bar backgroundColor="#FFFFFF" />
        <Title text="Our Product" color="#FFF" align="center" /> */}
        <Box sx={{ width: "50%" }}>
          <img
            src="./Home/eModule.svg"
            alt="eModule"
            style={{ width: "100%" }}
          />
        </Box>
        {/* <Title
          text="The Specialized ePlatform for Automating Digital Agreement"
          color="#FFF"
          align="center"
        /> */}
        <Typography className={classes.titleDescription}>
          The Specialized ePlatform for Automating Digital Agreement
        </Typography>

        {/* <Typography className={classes.description}>
          The core functionality of ePlatform
        </Typography> */}
      </Box>
      <EsignatureComponent />
      <Box className={classes.bottomContainer}>
        {productElements.map((product, index) => {
          return (
            <Box key={index} sx={{width: {sm: "100%", md:"50%"}, display:'flex', justifyContent:'center', alignItems:"center"}}>
              <Products
                title={product.title}
                description={product.description}
                image={product.image}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Esignature;
