import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { FaAward } from "react-icons/fa";

export const cardDetails = [
  {
    mobileIcon: (
      <EmojiObjectsIcon
        sx={{
          fontSize: "40px",
          color: "#5686E1",
          align: "center",
          width: "100%",
        }}
      />
    ),
    icon: (
      <EmojiObjectsIcon
        sx={{
          fontSize: "64px",
          marginBottom: "24px",
          color: "#5686E1",
          align: "center",
          width: "100%",
        }}
      />
    ),
    title: "Synergy and Innovation",
    description:
      "Collaborating with us foster innovation and  generation of new ideas by bringing together diverse perspectives and approaches.",
  },
  {
    mobileIcon: (
      <HandshakeIcon
        sx={{
          fontSize: "40px",
          color: "#5686E1",
          align: "center",
          width: "100%",
        }}
      />
    ),
    icon: (
      <HandshakeIcon
        sx={{
          fontSize: "64px",
          marginBottom: "24px",
          color: "#5686E1",
          align: "center",
          width: "100%",
        }}
      />
    ),
    title: "Long-Term Relationships",
    description:
      "Collaborating with us foster innovation and  generation of new ideas by bringing together diverse perspectives and approaches.",
  },
  {
    mobileIcon: (
      <FaAward
        style={{
          fontSize: "40px",
          color: "#5686E1",
          align: "center",
          width: "100%",
        }}
      />
    ),
    icon: (
      <FaAward
        style={{
          fontSize: "64px",
          marginBottom: "24px",
          color: "#5686E1",
          align: "center",
          width: "100%",
        }}
      />
    ),
    title: "Excellence & Commitment",
    description:
      "We excellence in software product development, commitment to achieve client satisfaction in meeting their business objectives.",
  },
];
