import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import EastIcon from "@mui/icons-material/East";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingBottom: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    gap: "16px",
    background:
      "linear-gradient(180deg, rgba(34, 35, 76, 0.80) 0%, rgba(31, 29, 64, 0.80) 100%)",
    margin: "40px 0px",
    borderRadius: "24px",
    height: "500px",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      margin: "0px",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "24px",
      paddingBottom: "40px",
    },
    [theme.breakpoints.only("sm")]: {
      height: "600px",
    },
    [theme.breakpoints.up("lg")]: {

     height: '100%',
    },
  },
  title: {
    fontFamily: "Urbanist",
    fontSize: "32px",
    fontWeight: "600",
    color: "#FFFFFF",
    marginTop: "40px",
  },
  description: {
    fontFamily: "Urbanist",
    fontSize: "18px",
    color: "#F7F7F7",
    textAlign: "center",
    width: "80%",
  },
  getInDetailButton: {
    fontFamily: "Urbanist",
    textTransform: "none",
    color: "#000000",
    background: "#FFFFFF",
    padding: "8px 20px",
    borderRadius: "20px",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
}));

const Products = ({title, description, image}) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>
        {description}
      </Typography>
      {/* <Button
        endIcon={<EastIcon />}
        disableTouchRipple
        className={classes.getInDetailButton}
      >
        Get in Detail
      </Button> */}

      <img
        src={image}
        alt="allsd"
        style={{ width: "80%", marginTop: "20px" }}
      />
    </Box>
  );
};

export default Products;
