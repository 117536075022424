import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { Link } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  title: {
    color: "#FFFFFF",
    fontFamily: "Urbanist",
    fontSize: "37px",
    fontWeight: "600",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0px",
      fontSize: "26px",
    },
  },
  JoinOurTeamContainer: {
    background: `url("./Home/Join Our Team.svg")`,
    backgroundSize: "cover",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      background: `url("./Home/Join Out Team Mobile Image.jpg")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
  },
  container: {
    background: `linear-gradient(180deg, rgba(20, 24, 65, 0.50) 0%, rgba(20, 24, 66, 0.50) 100%)`,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    padding: "40px 0px",
    [theme.breakpoints.up("lg")]: {
      height: "500px",
    },
  },
  description: {
    marginTop: "40px",
    color: "#FFFFFF",
    textAlign: "center",
    fontFamily: "Urbanist",
    fontWeight: "500",
    fontSize: "22px",
    width: "35%",
    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
      width: "70%",
    },

    [theme.breakpoints.down("md")]: {
      width: "80%",
      marginTop: "24px",
    },
  },
  findYourOpportunityButton: {
    background: "linear-gradient(180deg, #0069EC 0%, #3986E5 100%)",
    color: "#fff",
    fontFamily: "Urbanist",
    textTransform: "none",
    borderRadius: "30px",
    padding: "12px 24px",
    "&: hover": {
      background: "linear-gradient(180deg, #0069EC 0%, #3986E5 100%)",
    },
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "40px",
    marginTop: "30px",
  },
}));

const JoinOurTeam = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.JoinOurTeamContainer}>
      <Box className={classes.container}>
        <Typography className={classes.title}>Join Our Team</Typography>
        <Typography className={classes.description}>
          One place to provide flexibility, leanring curve and support to work
          with passion. You can be part of the culture.
        </Typography>
        <Button
          component={Link}
          to="/careers"
          disableTouchRipple
          className={classes.findYourOpportunityButton}
          onClick={() => window.scrollTo(0, 0)}
        >
          Find Your Opportunity
          <CallMadeIcon sx={{ fontSize: "15px", marginLeft: "5px" }} />
        </Button>
      </Box>
    </Box>
  );
};

export default JoinOurTeam;
