export const keyDriveElements = {
  imagePath: "./Service/KeyDrives/Blockchain Keydrive.svg",
  keyDrivers: [
    "Transparency",
    "Security from Tampering",
    "Need for Digital Contracting",
    "Enforcing Accountability",
    "Elimination of Third Party",
    "Maintenance cost of Digital Assets",
  ],
  path: "blockchain-solution",
};

export const benefits = {
  benefitElements: [
    "Data protection & privacy",
    "Decentralization",
    "Tokenization and Digital Assets",
    "Smart Contracts",
    "Immutable Ledger",
  ],
  image: "./Service/Benefits/Blockchain Benefit.svg",
};

export const content = {
  image: "./Service/Banners/Blochain banner.jpg",
  text: "Digital Transaction with Transparency & Trust ",
  mainHeading:
    "Paving the Way for a Trustworthy and Transparent Future with Blockchain",
  textBackground: "linear-gradient(180deg, #C1CBFF 0%, #3986E5 100%)",
  leftBackground: "#000",
};

export const about = {
  firstAbout: {
    serviceName: "Blockchain",
    description: `Blockchain technology a disruptive force with the potential to reshape industries, streamline processes, and empower individuals and businesses in novel and transformative ways.Macro Benefits of implementing blockchain include security with immutable data, scaled-up Trust levels due to the distributed nature of storage and efficiency in inter-Entity Transactions.`,
  },
  secondAbout: {
    serviceName: "Capabilities",
    description: `At VassuTech, Blockchain approach identifies potential areas of blockchain adoption in the Enterprise and their extended ecosystems and delivers solutions. Having successfully innovated and implemented Blockchain Framework in the Insurance businesses and may further apply and deploy disruptive blockchain solutions in other domain apart from insurance`,
  },
};
