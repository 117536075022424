import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import OurPresence from "./Elements/OurPresence";
import MeetOurExperts from "./Elements/MeetOurExperts";
import About from "../ReusableComponents/About";
import LetsGetInTouch from "../ReusableComponents/LetsGetInTouch";
import Banner from "../ReusableComponents/Banner";
import OurValues from "./Elements/OurValues/OurValues";
import OurStory from "./Elements/OurStory/OurStory";
import "../Styles/index.css";
import { useEffect } from "react";

const useStyles = makeStyles()((theme) => ({
  main: {
    [theme.breakpoints.up("md")]: {
      marginTop: "70px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  subContainer: {
    padding: "64px 100px",
    paddingBottom: "20px",
    [theme.breakpoints.down("md")]: {
      padding: "24px",
      width: "80%",
    },
  },
  textBanner: {
    width: "100%",
    backgroundSize: "cover",
    height: "100vh",
    margin: "64px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  bannerText: {
    fontSize: "45px",
    fontFamily: "Urbanist",
    fontWeight: "700",
    color: "#FFFFFF",
    background: "linear-gradient(180deg, #FFF 0%, #4E95FF 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    width: "65%",
  },
  bannerLeft: {
    background: "linear-gradient(136deg, #000 0%, #003474 100%)",
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bannerRight: {
    width: "50%",
  },
}));

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { classes } = useStyles();
  return (
    <Box className={classes.main}>
      <div className="container">
        <h1 className="mainHeading">
          Customer Satisfaction and Expertise in Information Consulting and
          Service domains makes us Trusted Partner
        </h1>
      </div>
      <Box className={classes.titleContainer}>
        {/* <MainHeading
          text="Focus on customer satisfaction & our expertise in information consulting  & service domains, makes us a trusted partner"
          background="linear-gradient(135deg, #0F143B 0%, #E65C4F 100%)"
          width="70%"
        /> */}

        <Banner
          background="linear-gradient(180deg, #FFF 0%, #4E95FF 100%)"
          text="Digital Journey marked by Commitment"
          imageMobile="./About/About Banner.jpg"
          image="./About/About Banner.jpg"
          leftBackground="linear-gradient(135deg, #000 0%, #00353E 100%)"
        />
        <Box className={classes.subContainer}>
          <About
            title="Foundation"
            description="VassuTech was founded with the vision of leveraging technology to drive innovation and growth. Over the years, we have grown from a small team of passionate individuals to a global team of experts, delivering innovative solutions to clients worldwide. Our journey has been marked by a commitment to excellence, integrity, and innovation, and we continue to stay true to these values in everything we do."
          />
          <About
            title="Expertise"
            description="VassuTech has proved its expertise in providing IT Services, Product Development and Consulting with a team of experts in different technology stacks, spanning from Digital Transformation to Data Security. Having a strong foundation in Enterprise Data Management solutions, VassuTech has expanded its footprint to other areas such as Middleware Integration using Microservices, Blockchain, BI Analytics, Product Development in Agile Methodology with DevOps in CI/CD pipeline and establishing Testing COE (Centre of Excellence for Quality Assurance in framing the best practices in both manual and testing automation)."
          />
          <About
            title="Commitment"
            description="VassuTech commitment to customer satisfaction and flexible culture that builds and sustains long-lasting relationships with our clients. Our due emphasize on delivering optimized solutions that benefit them to streamline their business process and deriving the value out of it. We also continue to strive with same the passion and sprit bounded by core values of VassuTech, while ensuring our client’s business growth."
          />
        </Box>
      </Box>
      <OurStory />
      <OurValues />
      <OurPresence />
      <MeetOurExperts />
      <LetsGetInTouch />
    </Box>
  );
};

export default AboutUs;
