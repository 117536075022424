import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { motion } from "framer-motion";
import "../../../Styles/index.css";

const useStyles = makeStyles()((theme) => ({
  title: {
    color: "#2F2F2F",
    fontSize: "32px",
    fontFamily: "Urbanist",
    fontWeight: "600",
    marginBottom: "16px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      margin: "20px 0px",
    },
  },
  description: {
    fontFamily: "Urbanist",
    color: "#6E6E6E",
    lineHeight: "30px",
    fontSize: "18px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      lineHeight: "25px",
    },
  },
}));

const OurStoryText = ({ title, description, moveFrom }) => {
  const { classes } = useStyles();
  return (
    <motion.div
      className="storyTextContainer"
      variants={{
        initial: {
          opacity: 0,
          x: moveFrom,
        },
        animate: () => ({
          opacity: 1,
          x: 0,
          transition: {
            delay: 0.5,
            duration: 1,
          },
        }),
      }}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
      transition={{ duration: 2 }}
    >
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
    </motion.div>
  );
};

export default OurStoryText;
