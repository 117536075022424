import {
  Alert,
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import emailjs from "emailjs-com";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

const useStyles = makeStyles()((theme) => ({
  container: {
    background: `#F7F7FF`,
    padding: "60px 80px",
    [theme.breakpoints.down("md")]: {
      padding: "40px 24px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px 100px",
    },
  },
  title: {
    color: "#05021C",
    fontFamily: "Urbanist",
    fontSize: "37px",
    fontWeight: "600",
    marginBottom: "30px",
    textAlign: "center",
  },
  label: {
    color: "#000000",
    fontFamily: "Urbanist",
    fontSize: "15px",
    fontWeight: "500",
  },
  textfield: {
    borderBottom: "1px solid #8D8D8D",
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  subContainer: {
    width: "100%",
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  radio: {
    border: "none",
    outline: "none",
  },
  selectSubject: {
    color: "#000000",
    fontFamily: "Urbanist",
    fontSize: "22px",
  },
  textarea: {
    "&:hover": {
      borderColor: "none",
    },
    "&:focus": {
      outline: "none",
      boxShadow: "none",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
    background: "#FFFFFF",
    width: "100%",
    borderRadius: "10px",
    marginTop: "16px",
  },
  contactButton: {
    fontSize: "18px",
    fontFamily: "Urbanist",
    textTransform: "none",
    color: "#FFFFFF",
    background: `#05021C`,
    borderRadius: "30px",
    "&: hover": {
      background: `#05021C`,
    },
    marginTop: "30px",
    padding: "10px",
    width: "100%",
    cursor: "pointer",
  },
  buttons: {
    marginBottom: "8px",
    borderRadius: "0px",
    textTransform: "none",
    color: "#C4C4C4",
    fontSize: "17px",
    fontFamily: "Urbanist",
    "&: hover": {
      background: "transparent",
    },
  },
  selectedButton: {
    padding: "0px",
    borderBottom: "3px solid #000000",
    textDecoration: "none",
    marginBottom: "8px",
    borderRadius: "0px",
    color: "#0F143B",
    fontWeight: "600",
    "&: hover": {
      background: "transparent",
      textDecoration: "none",
      fontSize: "17px",
    },
    textTransform: "none",
    fontFamily: "Urbanist",
    fontSize: "17px",
  },
  email: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Urbanist",
    color: "#0F143B",
    marginTop: "10px",
  },
  address: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "8px",
    fontFamily: "Urbanist",
    width: "300px",
    color: "#0F143B",
    marginTop: "10px",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "-10px",
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      padding: "0px",
      margin: "0px",
    },
  },
  leftContainer: {
    width: "40%",
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      marginBottom: "40px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      marginBottom: "40px",
      padding: "0px 30px",
    },
  },
  fieldSubContainer: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const locations = [
  {
    id: 1,
    location: "Canada",
  },
  {
    id: 2,
    location: "USA",
  },
  {
    id: 3,
    location: "India",
  },
];

const locationDetails = [
  {
    image: "./Home/Canada Location.svg",
    email: "info@vassutech.com",
    address:
      "Vassu Tech Services Inc. Venture Labs, 3600 Steeles Ave. E. Room B114, Markham",
  },
  {
    image: "./Home/US Location.svg",
    email: "info@vassutech.com",
    address:
      "Vassu Tech Services USA LLC,1700 Market Street, Suite 1005, Philadelphia, PA 19103 Suite 1005",
    phone: "+1 (215) 770-6961",
  },
  {
    image: "./Home/India Location.svg",
    email: "info@vassutech.com",
    address:
      "VassuTech Services Pvt. Ltd. 6th Floor, N Heights, Hi-Tech City, Madhapur, Hyderabad 500081",
  },
];

const LetsGetInTouch = () => {
  const { classes } = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isButtonSelected, setIsButtonSelected] = useState(0);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const handleButtonClick = (index) => {
    setIsButtonSelected(index);
  };

  const selectedLocation = locationDetails[isButtonSelected];

  function sendEmail(e) {
    e.preventDefault();

    if ((firstName, email, subject, message)) {
      emailjs
        .sendForm(
          "service_5ryua6g",
          "template_yx0kois",
          e.target,
          "hhwAL8gNITWypuRVD"
        )
        .then(
          (result) => {
            setIsMessageSent(true);
            // window.location.reload()
          },
          (error) => {
            console.log(error.text);
          }
        );
    }

    setFirstName("");
    setLastName("");
    setEmail("");
    setSubject("");
    setMessage("");
  }

  useEffect(() => {
    if (isMessageSent) {
      const timer = setTimeout(() => {
        setIsMessageSent(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isMessageSent]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Let’s get in Touch</Typography>
      <Box className={classes.subContainer}>
        <Box className={classes.leftContainer}>
          <form onSubmit={sendEmail}>
            <Box className={classes.fieldContainer}>
              <Box className={classes.fieldSubContainer}>
                <InputLabel className={classes.label}>First Name</InputLabel>
                <TextField
                  required
                  value={firstName}
                  type="text"
                  variant="standard"
                  name="from_first_name"
                  className={classes.textfield}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Box>
              <Box className={classes.fieldSubContainer}>
                <InputLabel className={classes.label}>Last Name</InputLabel>
                <TextField
                  value={lastName}
                  type="text"
                  variant="standard"
                  name="from_last_name"
                  className={classes.textfield}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
            </Box>
            <Box className={classes.fieldContainer}>
              <Box className={classes.fieldSubContainer}>
                <InputLabel className={classes.label}>Email</InputLabel>
                <TextField
                  required
                  value={email}
                  type="email"
                  variant="standard"
                  name="from_email"
                  className={classes.textfield}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Box className={classes.fieldSubContainer}>
                <InputLabel className={classes.label}>Subject</InputLabel>
                <TextField
                  required
                  value={subject}
                  type="text"
                  variant="standard"
                  name="subject"
                  className={classes.textfield}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Box>
            </Box>
            <Box>
              <Box my={2}>
                <Typography className={classes.label}>
                  Write your message..
                </Typography>
                <TextField
                  required
                  value={message}
                  multiline
                  rows={4}
                  className={classes.textarea}
                  name="input_msg"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Box>
              <Button
                type="submit"
                className={classes.contactButton}
                endIcon={<ForwardToInboxIcon />}
                disableTouchRipple
              >
                Send Email
              </Button>
              {/* <span
                style={{
                  justifyContent: "center",
                  fontSize: "20px",
                  alignContent: "center",
                  backgroundColor: "Green",
                }}
              >
                {emailMsg}
              </span> */}
              {isMessageSent && (
                <Alert
                  iconMapping={{
                    success: <CheckCircleOutlineIcon fontSize="inherit" />,
                  }}
                >
                  Message sent successfully
                </Alert>
              )}
            </Box>
          </form>
        </Box>

        <Box className={classes.rightContainer}>
          <Box>
            <Box>
              {locations.map((each, index) => (
                <Button
                  key={each.id}
                  disableTouchRipple
                  className={
                    isButtonSelected === index
                      ? classes.selectedButton
                      : classes.buttons
                  }
                  onClick={() => handleButtonClick(index)}
                >
                  {each.location}
                </Button>
              ))}
            </Box>
            <img
              src={selectedLocation.image}
              alt="sdf"
              style={{
                width: "100%",
                marginTop: "0px 10px",
              }}
            />
            <Typography className={classes.email} my={3}>
              <MailIcon />
              {selectedLocation.email}
            </Typography>
            <Typography className={classes.address}>
              {" "}
              <LocationOnIcon />
              {selectedLocation.address}
            </Typography>

            {selectedLocation.phone && (
              <Typography className={classes.address}>
                <LocalPhoneIcon />
                {selectedLocation.phone}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LetsGetInTouch;
