import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import BlogsSection from "./BlogsSection/BlogsSection";
import LetsGetInTouch from "../ReusableComponents/LetsGetInTouch";
import Banner from "../ReusableComponents/Banner";
import "../Styles/index.css";
const useStyles = makeStyles()((theme) => ({
  main: {
    [theme.breakpoints.up("md")]: {
      marginTop: "70px",
    },
  },
  headingContianer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

const Blogs = () => {
  const { classes } = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box className={classes.main}>
      <Box className={classes.headingContianer}>
        <div className="container">
          <h1 className="mainHeading">
            Read the Blogs that open up our social media Posts and Features
          </h1>
        </div>
      </Box>
      <Banner
        text="Dias for Sharing Thoughts and Stories"
        background="#FFF"
        leftBackground="linear-gradient(135deg, #000 0%, #370F0C 100%)"
        imageMobile="./Blogs/blogs banner.jpg"
        image="./Blogs/blogs banner.jpg"
      />
      <BlogsSection />
      <LetsGetInTouch />
    </Box>
  );
};

export default Blogs;
