// export const cardDetails = [
//   {
//     image: "./Blogs/MBA.jpg",
//     date: "Demi WIlkinson • 1 Jan 2023",
//     title: "Mortgage Bankers Association (MBA) Convention ",
//     tagline : "MBA is an event to focus on the challenges of Insurance Industry and explore the opportunities for digitization in addressing the challenges",
//     description:
//       "The forthcoming MBA – Starting from Oct. 15th to 18th- is expected to provide opportunity",
//     body: "The forthcoming MBA – Starting from Oct,15th to 18th at Philadelphia, USA- is expected to provide opportunity and explore the digital ideas and solutions that may further help is reducing processing costs, increases transparency in the Real Estate industry, especially leveraging MISMO data standards that promotes consistency in data exchange among stakeholders in the mortgage industry. For more informartion, visit at https://www.mba.org/ ",
//     buttons: [
//       {
//         text: "Product",
//         color: "#027A48",
//         backgroundColor: "#ECFDF3",
//       },
//       {
//         text: "Research",
//         color: "#3538CD",
//         backgroundColor: "#EEF4FF",
//       },
//       {
//         text: "Frameworks",
//         color: "#C4320A",
//         backgroundColor: "#FFF6ED",
//       },
//     ],
//   },

//   {
//     image: "./Blogs/Blog1.jpg",
//     date: "Alec Whitten • 1 Jan 2023",
//     title: "Edge Datacentres and Significance",
//     tagline : "In the world of Cloud Computing , though data can be accessed from anywhere, but speed of access and response time of data is taken priority for some of the business operations in the Enterprise",
//    shortdesc :"Though the data can be stored on premises or cloud based, there will be a context, where the data will have to be accessed and quick response expected from data centre.",
//     description:
//       "Edge Data Centres are data storage repositories similar to Data lakes but very...",
//     body: "Edge Data Centres are data storage repositories similar to data lakes but very smaller in size that are located close to End Users or Devises.  As these data centres are close to End Users or actual devises – in the light of IoT- those consume data, the data can be cached in Data Edge centres for faster access from devises. They are significant in the context of faster time response, wherein time sensitive data retrieval is vital in the business scenario or consumer point of view, where request and quick response is expected.",
//     buttons: [
//       {
//         text: "Leadership",
//         color: "#6941C6",
//         backgroundColor: "#F9F5FF",
//       },
//       {
//         text: "Management",
//         color: "#3538CD",
//         backgroundColor: "#EEF4FF",
//       },
//     ],
//   },
//   {
//     image: "./Blogs/Blog2.jpg",
//     date: "Candice Wu • 1 Jan 2023",
//     title: "Data Gravity",
//     tagline : "Data Size and Mass sometimes does matter when managing the data, if that can be pulled or pushed that may result in bringing more applications closer to data ",
//     description:"Data Gravity is similar to Gravity of Force inform of attraction, between Data and Applications",
//     body:
//       "Data Gravity is similar to Gravity of Force inform of attraction, but between Data and Applications that consume the data. Usually, Data Warehouse, Data lakes are big and large granaries of data across the business organization for access to vital data being aggregated from various sources.  This data is useful to dive into the insights for key business decisions. But when the data size grows and increases its volume, then it would be difficult to move. Instead, the applications will be move closer to that data, that require higher bandwidth and/or lower latency, to leverage the data.  This will necessitate to design and architect the solutions to avoid data pushing across networks.",
//     buttons: [
//       {
//         text: "Design",
//         color: "#6941C6",
//         backgroundColor: "#F9F5FF",
//       },
//       {
//         text: "Research",
//         color: "#3538CD",
//         backgroundColor: "#EEF4FF",
//       },
//     ],
//   },
//   {
//     image: "./Blogs/Blog3.jpg",
//     date: "Natali Craig • 1 Jan 2023",
//     title: "Data Protection",
//     tagline : "Data is an asset for business growth, but turns into liability if not secured and protected ",
//     shortdesc :"As companies protect their data in various forms while architecting the design and solution such as  Access Control List and other Authentication measures, but they may be inherent gaps, complexity, and risk.",
//     body: "Protegrity Data Solutions protects the data itself, wherever it is, which lowers security risks and ensures policy compliance while hiding the sensitive data. Data tokenization is mechanism where the sensitive information, which is part or subset of complete data element (token), is replaced with some other data element, which has no explicit meaning that can be inferred, when someone look at the data. This is different from encryption, where in complete data is converted to cypher text that can’t be read.",
//     description:
//       "Protegrity Data Solutions protects the data itself, wherever it is, which lowers security risks and ensures policy compliance while hiding the sensitive data.",
//     buttons: [
//       {
//         text: "Design",
//         color: "#6941C6",
//         backgroundColor: "#F9F5FF",
//       },
//       {
//         text: "Research",
//         color: "#3538CD",
//         backgroundColor: "#EEF4FF",
//       },
//     ],
//   },
//   {
//     image: "./Blogs/Blog4.jpg",
//     date: "Drew Cano • 1 Jan 2023",
//     title: "Our top 10 Javascript frameworks to use",
//     description:
//       "JavaScript frameworks make development easy with extensive features and functionalities.",
//     body: "To Update",
//     buttons: [
//       {
//         text: "Software Development",
//         color: "#027A48",
//         backgroundColor: "#ECFDF3",
//       },
//       {
//         text: "Tools",
//         color: "#C11574",
//         backgroundColor: "#FDF2FA",
//       },
//       {
//         text: "SaaS",
//         color: "#C01048",
//         backgroundColor: "#FFF1F3",
//       },
//     ],
//   },

//   {
//     image: "./Blogs/Blog6.jpg",
//     date: "Orlando Diggs • 1 Jan 2023",
//     title: "Podcast: Creating a better CX Community",
//     description:
//       "Starting a community doesn’t need to be complicated, but how do you get started?",
//     body: "To Update",
//     buttons: [
//       {
//         text: "Podcasts",
//         color: "#6941C6",
//         backgroundColor: "#F9F5FF",
//       },
//       {
//         text: "Customer Success",
//         color: "#363F72",
//         backgroundColor: "#F8F9FC",
//       },
//     ],
//   },
// ];

export const cardDetails = [
  {
    image: "./Home/PHOTO-2023-10-16-04-55-46.jpg",
    date: "Demi WIlkinson • 1 Jan 2023",
    title: "MBA Convention, OCT, 2023 ",
    tagline:
      "MBA (Mortgage Bankers Association) is an event to focus on the challenges of Insurance Industry and explore the opportunities for digitization in addressing the challenges",
    description:
      "The forthcoming MBA – Starting from Oct. 15th to 18th- is expected to provide opportunity",
    body:
      //  "The forthcoming MBA – Starting from Oct,15th to 18th at Philadelphia, USA- is expected to provide opportunity and explore the digital ideas and solutions that may further help is reducing processing costs, increases transparency in the Real Estate industry, especially leveraging MISMO data standards that promotes consistency in data exchange among stakeholders in the mortgage industry. For more informartion, visit at https://www.mba.org/ ",
      "The MBA – Expo happened from Oct,15th to 18th, 2023 at Philadelphia, USA- was provided an opportunity for VassuTech to explore the digital ideas and solutions that would further help in reducing processing costs, increases transparency in the Real Estate industry, especially leveraging MISMO data standards that promotes consistency in data exchange among stakeholders in the mortgage industry.",
    buttons: [
      {
        text: "Product",
        color: "#027A48",
        backgroundColor: "#ECFDF3",
      },
      {
        text: "Research",
        color: "#3538CD",
        backgroundColor: "#EEF4FF",
      },
      {
        text: "Frameworks",
        color: "#C4320A",
        backgroundColor: "#FFF6ED",
      },
    ],
  },

  {
    image: "./Blogs/Blog1.jpg",
    date: "Alec Whitten • 1 Jan 2023",
    title: "EDGE DATA CENTER",
    tagline:
      "In the world of Cloud Computing , though data can be accessed from anywhere, but speed of access and response time of data is taken priority for some of the business operations in the Enterprise",
    shortdesc:
      "Though the data can be stored on premises or cloud based, there will be a context, where the data will have to be accessed and quick response expected from data centre.",
    description:
      "Edge Data Centres are data storage repositories similar to Data lakes but very...",
    body: "Edge Data Centres are data storage repositories similar to data lakes but very smaller in size that are located close to End Users or Devises.  As these data centres are close to End Users or actual devises – in the light of IoT- those consume data, the data can be cached in Data Edge centres for faster access from devises. They are significant in the context of faster time response, wherein time sensitive data retrieval is vital in the business scenario or consumer point of view, where request and quick response is expected.",
    buttons: [
      {
        text: "Leadership",
        color: "#6941C6",
        backgroundColor: "#F9F5FF",
      },
      {
        text: "Management",
        color: "#3538CD",
        backgroundColor: "#EEF4FF",
      },
    ],
  },
  {
    image: "./Blogs/Blog2.jpg",
    date: "Candice Wu • 1 Jan 2023",
    title: "DATA GRAVITY",
    tagline:
      "Data Size and Mass sometimes does matter when managing the data, if that can be pulled or pushed that may result in bringing more applications closer to data ",
    description:
      "Data Gravity is similar to Gravity of Force inform of attraction, between Data and Applications",
    body: "Data Gravity is similar to Gravity of Force inform of attraction, but between Data and Applications that consume the data. When the data size grows and increases its volume, then it would be difficult to move the data. Instead, the applications will be move closer to that data, that require higher bandwidth and/or lower latency, to leverage the data.  This will necessitate to design and architect the solutions to avoid data pushing across networks.",
    buttons: [
      {
        text: "Design",
        color: "#6941C6",
        backgroundColor: "#F9F5FF",
      },
      {
        text: "Research",
        color: "#3538CD",
        backgroundColor: "#EEF4FF",
      },
    ],
  },
  {
    image: "./Blogs/Blog3.jpg",
    date: "Natali Craig • 1 Jan 2023",
    title: "PII DATA PROTECTION",
    tagline:
      "Data is an asset for business growth, but turns into liability if not secured and protected ",
    shortdesc:
      "As companies protect their data in various forms while architecting the design and solution such as  Access Control List and other Authentication measures, but they may be inherent gaps, complexity, and risk.",
    body: "Protegrity Data Solutions protects the data itself, wherever it is, which lowers security risks and ensures policy compliance while hiding the sensitive data. Data tokenization is mechanism where the sensitive information, which is part or subset of complete data element (token), is replaced with some other data element, which has no explicit meaning that can be inferred, when someone look at the data. This is different from encryption, where in complete data is converted to cypher text that can’t be read.",
    description:
      "Protegrity Data Solutions protects the data itself, wherever it is, which lowers security risks and ensures policy compliance while hiding the sensitive data.",
    buttons: [
      {
        text: "Design",
        color: "#6941C6",
        backgroundColor: "#F9F5FF",
      },
      {
        text: "Research",
        color: "#3538CD",
        backgroundColor: "#EEF4FF",
      },
    ],
  },
  {
    image: "./Blogs/Blog6.jpg",
    date: "Drew Cano • 1 Jan 2023",
    title: "UI/UX SIGNIFICANCE IN TODAY'S WORLD",
    description:
      "UI/UX design plays an instrumental role in winning over customers’ loyalty",
    body: "It’s undeniable that UI/UX design plays an instrumental role in winning over customers’ loyalty after every visit. But, why exactly are these design functions so crucial for attaining success in today’s digital world? Let’s dive into the nitty-gritty of UI/UX design and how these two fields shape our digital experiences.UX design is subjective. There is no single definition of good/bad UX design. With that being said, all UX design practices have the same objective: to make the interactions between users and digital products more pleasurable, efficient, and engaging.",
    buttons: [
      {
        text: "Software Development",
        color: "#027A48",
        backgroundColor: "#ECFDF3",
      },
      {
        text: "Tools",
        color: "#C11574",
        backgroundColor: "#FDF2FA",
      },
      {
        text: "SaaS",
        color: "#C01048",
        backgroundColor: "#FFF1F3",
      },
    ],
  },

  {
    image: "./Blogs/Blog4.jpg",
    date: "Orlando Diggs • 1 Jan 2023",
    title: "SOFTWARE TEST MAINTENANCE",
    description:
      "Test maintenance is the critical part to keep the test up to date with code changes resulting from Business scenario",
    body: "Business scenarios may change and also in components of application. These changes may result in updating the test cases as per automation framework. Test maintenance is the critical part to keep the test up to date with code changes resulting from new business scenario. This is where, the significance of CI/CD pipeline with DevOps practices will come into picture to resolve any conflicts more efficiently and quickly. In testing perspective, the successful DevOps implementation will attributed to as how Continuous Integration and Deployment(CI/CD) is implemented. ",
  },
];
