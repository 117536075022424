import { Box, Typography } from "@mui/material";
import Bar from "../ReusableComponents/Bar";
import Title from "../ReusableComponents/Title";
import { makeStyles } from "tss-react/mui";
import { motion } from "framer-motion";
import { useState } from "react";
import "./index.css";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 100px",
    [theme.breakpoints.down("md")]: {
      padding: "40px 0px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
  description: {
    textAlign: "center",
    width: "80%",
    fontFamily: "Urbanist",
    fontSize: "20px",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5%",
    marginTop: "48px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "0px 10%",
      gap: "20px",
    },
  },
  cardTitle: {
    fontSize: "24px",
    fontFamily: "Urbanist",
    fontWeight: "600",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
  },
  cardDescription: {
    fontSize: "16px",
    fontFamily: "Urbanist",
    margin: "15px 0px",
    fontWeight: "300",
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      margin: "12px 0px",
    },
  },
}));

const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;

const GetHiredWithUs = () => {
  const { classes } = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#000000" />
        <Title color="#000000" text="Get Hired with us" align="center" />
        <Typography className={classes.description}>
          Looking for Experienced and Talented people both in India and Canada.
        </Typography>
      </Box>
      <Box className={classes.cardContainer}>
        {/* <Box
          sx={{
            width: { md: "40%", lg: "32%" },
          }}
        >
          <img
            src="./Careers/Get Hired Image.svg"
            alt="sadf"
            style={{ width: "100%" }}
          />
        </Box> */}
        <motion.div
          className="hiredImageContaiener"
          initial={false}
          animate={
            isLoaded && isInView
              ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
              : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
          }
          transition={{ duration: 1, delay: 0.5 }}
          viewport={{ once: true }}
          onViewportEnter={() => setIsInView(true)}
        >
          <img
            src="./Careers/Get Hired Image.svg"
            alt=""
            onLoad={() => setIsLoaded(true)}
            style={{ width: "100%" }}
          />
        </motion.div>
        <Box sx={{ width: { xs: "80%", md: "40%" } }}>
          <Box>
            <Typography className={classes.cardTitle}>
              Looking forward to shift your Career
            </Typography>
            <Typography className={classes.cardDescription}>
              Current Openings details and Job Description are given below
            </Typography>
            <Box my={3}>
              <Typography className={classes.cardTitle}>
                Apply with us
              </Typography>
              <Typography className={classes.cardDescription}>
                If there are Job openings matched to your skill set, you can
                send your CV to{" "}
                <b style={{ color: "blue" }}>info@vassutech.com </b> and CC to{" "}
                <b style={{ color: "blue" }}>hr.india@vassutech.com</b>
              </Typography>
            </Box>
          </Box>
          <Typography className={classes.cardTitle}>
            Get an Interview call
          </Typography>
          <Typography className={classes.cardDescription}>
            Our Talent Acquisition Team will call you after evaluation and
            initial screening of your profile subjected to open positions.
          </Typography>
          {/* <Typography className={classes.cardTitle}>
            Screening Process
          </Typography>
          <Typography className={classes.cardDescription}>
            For further screening, there will be more than one round of
            discussion both at Technical level and Managerial level. Proxy
            interview and impersonation will not be allowed.
          </Typography>
          <Typography className={classes.cardTitle}>
            Joining Formalities (Appointment)
          </Typography>
          <Typography className={classes.cardDescription}>
            Once the candidate is selected, she/he will be asked to join on or
            before the agreed joining date. There will be a BGV (Background
            Verification) for every new joiner.
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default GetHiredWithUs;
