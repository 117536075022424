import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";
import Bar from "../../../ReusableComponents/Bar";
import { cardDetails } from "./CardDetails";
import Slider from "react-slick";
import { useRef } from "react";

const useStyles = makeStyles()((theme) => ({
  container: {
    background: "linear-gradient(134deg, #05021C 2.11%, #141750 100%)",
    padding: "80px 70px",
    paddingRight: "50px",
    [theme.breakpoints.only("xs")]: {
      paddingRight: "0px",
      padding: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      paddingRight: "0px",
      padding: "24px",
    },
    [theme.breakpoints.only("md")]: {
      paddingRight: "0px",
      padding: "40spx",
    },
  },
  title: {
    fontFamily: "Urbanist",
    fontSize: "40px",
    fontWeight: "600",
    marginBottom: "24px",
    width: "300px",
    color: "#fff",
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      fontSize: "26px",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flexDirection: "column",
      textAlign: "center",
    },
  },
  subContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  leftContainer: {
    width: "40%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  description: {
    fontSize: "20px",
    fontFamily: "Urbanist",
    color: "#fff",
    width: "80%",
    marginRight: "10px",
    textAlign: "justify",
    marginBottom: "24px",
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
      fontSize: "20px",
      marginBottom: "40px",
      width: "95%",
      marginRight: "0px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "22px",
    },
  },
  arrowButton: {
    background: "rgba(151, 151, 151, 0.16)",
    "&:hover": {
      background: "rgba(151, 151, 151, 0.16)",
    },
    margin: "5px",
    borderRadius: "0px",
    padding: "16px 0px",
  },
  card: {
    boxSizing: "border-box",
    background:
      "linear-gradient(180deg, rgba(52, 58, 100, 0.70) 0%, rgba(55, 63, 98, 0.70) 100%)",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    padding: "32px 28px",
    height: "320px",
    marginRight: "20px",
    [theme.breakpoints.down("lg")]: {
      padding: "24px 28px",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
      borderRadius: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      boxSizing: "border-box",
    },
    [theme.breakpoints.only("sm")]: {
      width: "327px",
      height: "327px",
      marginTop: "8px",
    },

    [theme.breakpoints.only("xs")]: {
      width: "300px",
      height: "300px",
      marginTop: "8px",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "40px",
      width: "290px",
      borderRadius: "16px",
      height: "290px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "320px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  cardTitle: {
    fontSize: "18px",
    fontFamily: "Urbanist",
    color: "#FFFFFF",
    fontWeight: "600",
    marginBottom: "16px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "20px",
    },
  },
  cardDescription: {
    fontSize: "16px",
    fontFamily: "Urbanist",
    color: "#FFFFFF",
    textAlign: "justify",
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0px 10px",
    },
    [theme.breakpoints.only("md")]: {
      margin: "0px 10px",
      textAlign: "center",
    },
  },
  rightContainer: {
    width: "60%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  iconContainer: {
    overflow: "none",
    background: "rgba(10, 8, 43, 0.30)",
    height: "80px",
    width: "80px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
}));

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

const BeaingPartner = () => {
  const { classes } = useStyles();

  const settings = {
    arrows: false,
    dots: false,
    className: "center",
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    useCSS: "true",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const slider = useRef(null);

  return (
    <Box className={classes.container}>
      <Box className={classes.subContainer}>
        <Box className={classes.leftContainer}>
          <Box className={classes.titleContainer}>
            <Bar backgroundColor="#fff" />
            <Typography className={classes.title}>
              Being A Partner With Us
            </Typography>
            <Typography className={classes.description}>
              Being a Partner with us, our journey with you in transforming your
              IT landscape that offers the unique benefits, features, or
              advantages that your products or services provide to customers.
            </Typography>
          </Box>
          <Box sx={{ marginTop: "20px", display: { xs: "none", lg: "block" } }}>
            <Button
              disableTouchRipple
              className={classes.arrowButton}
              onClick={() => slider.current.slickPrev()}
            >
              <KeyboardBackspaceIcon
                sx={{ "&:hover": { color: "#ffffff" }, color: "#A1A1AA" }}
              />
            </Button>
            <Button
              disableTouchRipple
              className={classes.arrowButton}
              onClick={() => slider.current.slickNext()}
            >
              <EastIcon
                sx={{ "&:hover": { color: "#ffffff" }, color: "#A1A1AA" }}
              />
            </Button>
          </Box>
        </Box>

        <Box className={classes.rightContainer}>
          <Box
            sx={{
              display: { md: "block", xs: "none" },
              width: { md: "650px", lg: "90%" },
              
            }}
          >
            <Slider ref={slider} {...settings}>
              {cardDetails.map((eachCard, index) => (
                <Box key={index} mr={4}>
                  <Box className={classes.card}>
                    {eachCard.icon}
                    <Typography className={classes.cardTitle}>
                      {eachCard.title}
                    </Typography>
                    <Typography className={classes.cardDescription}>
                      {eachCard.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Box className={classes.rightContainer}>
            {cardDetails.map((eachCard, index) => (
              <Box key={index} mb={2}>
                <Box className={classes.card}>
                  <Box className={classes.iconContainer}>
                    {eachCard.mobileIcon}
                  </Box>
                  <Typography className={classes.cardTitle}>
                    {eachCard.title}
                  </Typography>
                  <Typography className={classes.cardDescription}>
                    {eachCard.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            display: { xs: "none", md: "block", lg: "none" },
          }}
        >
          <Button
            disableTouchRipple
            className={classes.arrowButton}
            onClick={() => slider.current.slickPrev()}
          >
            <KeyboardBackspaceIcon
              sx={{ "&:hover": { color: "#ffffff" }, color: "#A1A1AA" }}
            />
          </Button>
          <Button
            disableTouchRipple
            className={classes.arrowButton}
            onClick={() => slider.current.slickNext()}
          >
            <EastIcon
              sx={{ "&:hover": { color: "#ffffff" }, color: "#A1A1AA" }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BeaingPartner;
