import {
  AppBar,
  Box,
  Button,
  Slide,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    background: "#FFFFFF",
    boxShadow: "none",
  },
  routeElements: {
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 500,
    color: "#1E1E1E",
    textTransform: "none",
    background: "#F7F7FF",
    borderRadius: "25px",
    marginRight: "24px",
    padding: "5px 20px",
    "&:hover": {
      background: "#F7F7FF",
    },
  },
  currentRoute: {
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "none",
    color: "#FFFFFF",
    background: "linear-gradient(180deg, #0069EC 0%, #4E95FF 100%)",
    borderRadius: "25px",
    marginRight: "24px",
    padding: "5px 20px",
    "&:hover": {
      color: "#FFFFFF",
      background: "linear-gradient(180deg, #0069EC 0%, #4E95FF 100%)",
    },
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const menuElements = [
  {
    button: "Home",
    path: "/",
    url: "/",
  },
  {
    button: "About Us",
    path: "/about",
    url: "/about",
  },
  {
    button: "Services",
    path: "/services",
    url: "/services/application-integration",
  },
  {
    button: "Careers",
    path: "/careers",
    url: "/careers",
  },
  {
    button: "Blogs & Articles",
    path: "/blogs",
    url: "/blogs",
  },
];

const DescktopHeader = (props) => {
  const { classes } = useStyles();
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const activeButton = pathParts[1] || "";

  return (
    <Box>
      <HideOnScroll {...props}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Box component={Link} to="/" sx={{ flexGrow: "1" }}>
              <img
                src="./Home/Website Logo.svg"
                alt="asdf"
                style={{ width: "150px" }}
              />
            </Box>
            <Box>
              {menuElements.map((eachElement, index) => (
                <Button
                  key={index}
                  disableTouchRipple
                  component={Link}
                  to={eachElement.url}
                  onClick={() => window.scrollTo(0, 0)}
                  className={
                    `/${activeButton}` === eachElement.path
                      ? classes.currentRoute
                      : classes.routeElements
                  }
                >
                  {eachElement.button}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Box>
  );
};

export default DescktopHeader;
