import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../../index.css";

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    // background: "linear-gradient(to bottom, #05021C 64%, #FFFFFF 36%)",
    padding: "64px 100px",
    [theme.breakpoints.only("xs")]: {
      // background: "#05021C",
      padding: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px",
      // background: "linear-gradient(to bottom, #05021C 56%, #FFFFFF 44%)",
    },
    [theme.breakpoints.only("md")]: {
      // background: "linear-gradient(to bottom, #05021C 60%, #FFFFFF 40%)",
      padding: "64px 70px",
    },
  },
  subContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    [theme.breakpoints.only("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "40px",
    },
  },
  rightContainer: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      gap: "50px",
    },
  },
  leftContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "column",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  descriptionContainer: {
    marginBottom: "52px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "0px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  percentageContainer: {
    marginTop: "-50px",
    width: "270px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      width: "240px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "200px",
    },
  },
  percentageSubContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    padding: "16px 40px",
    [theme.breakpoints.down("lg")]: {
      padding: "14px 30px",
    },
  },
  percentage: {
    fontFamily: "Urbanist",
    fontWeight: "1000",
    fontSize: "25px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
    },
  },
  percentageText: {
    fontSize: "14px",
    fontFamily: "Urbanist",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
  ourMission: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 70px",
  },
  ourMissionText: {
    color: "#fff",
    fontFamily: "Urbanist",
    fontSize: "30px",
    letterSpacing: "1px",
    fontWeight: "600",
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      textAlign: "center",
      marginTop: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      textAlign: "left",
    },
  },
  buttonContainer: {
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
  },
  moreButton: {
    fontFamily: "Urbanist",
    color: "#fff",
    background: "#0069EC",
    textTransform: "none",
    borderRadius: "30px",
    padding: "12px 24px",
    "&: hover": {
      background: "#0069EC",
    },
    marginTop: "20px",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "600",
    letterSpacing: "0px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      padding: "8px 16px",
    },
  },
  ourMissionHighlights: {
    width: "250px",
    textAlign: "center",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#05021C",
    fontFamily: "Urbanist",
    gap: "1px",
    background: "#F5FAFF",
    borderRadius: "35px",
    padding: "18px 24px",
    fontWeight: "600",
    fontSize: "18px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
      padding: "16px 22px",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "18px",
    },
    [theme.breakpoints.only("md")]: {
      width: "200px",
      marginBottom: "0px",
    },
  },
  highlightsContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "0px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      gap: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      gap: "24px",
    },

    [theme.breakpoints.only("xs")]: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: "0px",
      marginTop: "0px",
    },
    [theme.breakpoints.only("sm")]: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  highlightsBottomContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      marginTop: "0px",
    },
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 20px",
    },
    boxSizing: "border-box",
  },
  highlightsMainContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
    marginTop: "28px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      marginBottom: "16px",
    },
  },
}));

const OurMission = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#0069EC" />
        <Title text="Our Mission" color="#000000" align="center" />
      </Box>
      <Box className={classes.subContainer}>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <Box className={classes.descriptionContainer}>
            <motion.h1
              className="ourMissionText"
              variants={{
                initial: {
                  opacity: 0,
                  x: 200,
                },
                animate: () => ({
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: 0.5,
                    duration: 1,
                  },
                }),
              }}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              transition={{ duration: 2 }}
            >
              Streamline IT Operations
              <br />
              Enhance Productivity
              <br />
              Adhere to Compliance
              <br />
              Optimise Business Growth
            </motion.h1>
            {/* <Box className={classes.buttonContainer}>
              <Button
                disableTouchRipple
                className={classes.moreButton}
                component={Link}
                to="/about"
                onClick={() => window.scrollTo(0, 0)}
              >
                More...
              </Button>
            </Box> */}
          </Box>
        </Box>
        <Box className={classes.leftContainer}>
          <Box
            sx={{
              display: { xs: "none", lg: "block" },
              marginTop: { lg: "50px" },
            }}
          >
            <img
              src="./Home/Our Mission.svg"
              alt=""
              style={{
                width: "80%",
                borderRadius: "20px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: { lg: "none", md: "block", xs: "none" },
              marginRight: "10%",
            }}
          >
            <img
              src="./Home/Our Mission.svg"
              alt="Our Mission"
              style={{
                marginTop: "20px",
                width: "100%",
                borderRadius: "20px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "none" },
            }}
          >
            <img
              src="./Home/Our Mission.jpg"
              alt="Our Mission"
              style={{
                marginTop: "20px",
                width: "300px",
                borderRadius: "20px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block", md: "none" },
            }}
          >
            <img
              src="./Home/Our Mission.jpg"
              alt="Our Mission"
              style={{
                marginTop: "20px",
                width: "100%",
                borderRadius: "20px",
              }}
            />
          </Box>
          <Box className={classes.percentageContainer}>
            <Box
              className={classes.percentageSubContainer}
              sx={{
                background: "#ffc526",
                borderRadius: "10px 0px 0px 10px",
              }}
            >
              <Typography
                className={classes.percentage}
                sx={{ color: "#000000" }}
              >
                95%
              </Typography>
              <Typography
                className={classes.percentageText}
                sx={{ color: "#000000" }}
              >
                Meeting SLA
              </Typography>
            </Box>
            <Box
              className={classes.percentageSubContainer}
              sx={{
                background: "#fff",
                borderRadius: "0px 10px 10px 0px",
              }}
            >
              <Typography className={classes.percentage}>100%</Typography>
              <Typography className={classes.percentageText}>
                Customer Satisfaction
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.rightContainer}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Box className={classes.descriptionContainer}>
              <motion.h1
                className="ourMissionText"
                variants={{
                  initial: {
                    opacity: 0,
                    x: 200,
                  },
                  animate: () => ({
                    opacity: 1,
                    x: 0,
                    transition: {
                      delay: 0.5,
                      duration: 1,
                    },
                  }),
                }}
                initial="initial"
                whileInView="animate"
                viewport={{
                  once: true,
                }}
                transition={{ duration: 2 }}
              >
                Streamline IT Operations
                <br /> Enhance Productivity
                <br /> Adhere to Compliance
                <br /> Optimise Business Growth
              </motion.h1>
              {/* <Box className={classes.buttonContainer}>
                <Button
                  component={Link}
                  to="/about"
                  disableRipple
                  className={classes.moreButton}
                >
                  More..
                  <CallMadeIcon sx={{ fontSize: "22px", marginLeft: "5px" }} />
                </Button>
              </Box> */}
            </Box>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Box className={classes.highlightsMainContainer}>
              <Box className={classes.highlightsContainer}>
                <Typography className={classes.ourMissionHighlights}>
                  <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
                  Innovation
                </Typography>
                <Typography className={classes.ourMissionHighlights}>
                  <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
                  Value Proposition
                </Typography>
              </Box>
              <Box className={classes.highlightsContainer}>
                <Typography className={classes.ourMissionHighlights}>
                  <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
                  Accessibility
                </Typography>
                <Typography className={classes.ourMissionHighlights}>
                  <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
                  Collaboration
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box className={classes.highlightsMainContainer}>
          <Box className={classes.highlightsContainer}>
            <Typography className={classes.ourMissionHighlights}>
              <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
              Innovation
            </Typography>
            <Typography className={classes.ourMissionHighlights}>
              <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
              Value Proposition
            </Typography>
          </Box>
          <Box className={classes.highlightsContainer}>
            <Typography className={classes.ourMissionHighlights}>
              <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
              Accessibility
            </Typography>
            <Typography className={classes.ourMissionHighlights}>
              <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
              Collaboration
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OurMission;
