export const keyDriveElements = {
  imagePath: "./Service/KeyDrives/BI ML Keydrive.svg",
  keyDrivers: [
    "Decision Support",
    "Strategic Planning and Forecast",
    "Regulatory Compliance",
    "Operational Efficiency",
    "Data Governance",
    "Competitive Advantage",
  ],
  path: "bi-ai-ml",
};

export const benefits = {
  benefitElements: [
    "Vital Data Access to Business Portfolio levels",
    "Faster Decision Arrival for Business Growth",
    "Single Source of Data Access from multiple systems",
    "Cost Savings resulted from Data Analysis",
    "Driving as Key Inputs for Other Business Systems",
  ],
  image: "./Service/Benefits/BI ML Benefit.svg",
};
export const content = {
  image: "./Service/Banners/bi banner.jpg",
  text: "Engaging Intelligence that Empowers the Business",
  mainHeading:
    "Integrating AI-Powered Business Intelligence for Data-Driven Excellence",
  textBackground: "linear-gradient(180deg, #FFF 0%, #C4C4C4 100%)",
  leftBackground: "#000",
};

export const about = {
  firstAbout: {
    serviceName: "BI and AI/ML",
    description: `Business Intelligence (BI) refers to the technologies, processes, and tools used to gather, analyze, transform, and present business data in order to support decision-making. Enterprise Business Intelligence shapes their policy, strategies and fine-tuning processes, BI thrives on a healthy applied analytics practice and Analytics in-turn depends how well the data landscape is organised and prepared for churning out dependable analytics.`,
  },
  secondAbout: {
    serviceName: "Capabilities",
    description: `VassuTech's approach of Data first prepares the premise for launching Analytics practice. Analytics quantify Enterprise processes so that they drive optimal decisions after substantial data upstream activities like data mining and application of several analyses ranging from statistical, descriptive, predictive, through perspective.Expert Resources deployed by VassuTech are lead and guided by the goals set by the overall BI strategy established for the Enterprise and perform adeptly to achieve individual and collective objectives of the BI initiatives.`,
  },
};
