import { Box, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "64px",
    paddingBottom: "64px",
    [theme.breakpoints.only("xs")]: {
      padding: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px 100px",
    },
  },
  description: {
    fontSize: "22px",
    fontFamily: "Urbanist",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      width: "100%",
      marginBottom: "24px",
    },
  },
}));

const OurProcess = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Bar backgroundColor="#000000" />
      <Title text="Our Process" color="#000000" align="center" />
      <Typography className={classes.description}>
        An abstract representation of iterative builds, blended with Agile and
        DevOps practices in delivering the
        <br />
        optimized solution
      </Typography>

      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src="./Home/Our Process.svg"
          alt="cicle"
          style={{ width: "500px" }}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          width: { xs: "80%", sm: "100%" },
        }}
      >
        <img
          src="./Home/Our Process.svg"
          alt="cicle"
          style={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
};

export default OurProcess;
