import { Box, Typography } from "@mui/material";
import Title from "../ReusableComponents/Title";
import Bar from "../ReusableComponents/Bar";
import { makeStyles } from "tss-react/mui";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { useState, useEffect } from "react";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 100px",
    background: "#F7F7FF",
    [theme.breakpoints.down("md")]: {
      padding: "40px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
  cardContaier: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFF",
    padding: "28px",
    borderRadius: "16px",
    margin: "10px",
    marginBottom: "24px",
    cursor: "pointer",
    "&: hover": {
      background: "#0069EC",
      color: "#FFFFFF",
    },
  },
  role: {
    fontSize: "20px",
    fontWeight: "600",
    fontFamily: "Urbanist",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  type: {
    fontSize: "12px",
    fontFamily: "Urbanist",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    background: "#FFFFFF",
    marginBottom: "20px",
    padding: "20px",
    borderRadius: "10px",
    gap: "15px",
    cursor: "pointer",
  },
  descriptionContainer: {
    padding: "64px 100px",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },
  jobType: {
    color: "#6E6E6E",
    fontFamily: "Urbanist",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "24px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  title: {
    color: "#13135F",
    fontFamily: "Urbanist",
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: "18px",
    },
  },
  description: {
    color: "#6E6E6E",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: "20px",
    lineHeight: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  skills: {
    color: "#6E6E6E",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: "6px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  applyButton: {
    width: "100%",
    background: "linear-gradient(180deg, #0069EC 0%, #3986E5 100%)",
    borderRadius: "25px",
    fontFamily: "Urbanist",
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "24px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  dialogContainer: {
    padding: "64px 100px",
    background: "#F7F7FF",
    [theme.breakpoints.down("md")]: {
      padding: "40px",
    },
  },
}));

const CurrentOpenings = () => {
  const { classes } = useStyles();
  const [cardDetails, setCardDetails] = useState([]);

  const handleClickOpen = (onpendJob) => {
    window.open(onpendJob, "_blank");
  };

  useEffect(() => {
    fetch("data/jd.json")
      .then((r) => r.json())
      .then((data) => setCardDetails(data));
  }, []);

  // useEffect(() => {
  //   fetch("data/jd.txt")
  //  .then( r=> r.text())
  //  .then(data => setCardDetails(JSON.parse(data)) )
  //   console.log(cardDetails);
  // }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#000000" />
        <Title text="Current Openings" color="#000000" align="center" />
      </Box>
      <Box>
        {cardDetails.map((eachCard, index) => (
          <Box
            key={index}
            className={classes.card}
            onClick={() => handleClickOpen(eachCard.pdfPath)}
          >
            <Box>
              <Typography className={classes.role}>
                {eachCard.jobRole}
              </Typography>
              <Typography className={classes.type}>
                {eachCard.jobType}{" "}
              </Typography>
            </Box>
            <CallMadeIcon />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CurrentOpenings;
