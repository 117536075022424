export const productElements = [
    {
        title: 'eNotarization',
        description: "Digital validation and authe-ntication of documents signed digitally using virtual signature.",
        image: "./Home/eNotarization.svg"
    },
    {
        title: 'eVault',
        description: "Digital Repository for storage of all digital documents protected by security and access control.",
        image: "./Home/eVault.svg"
    },
]