import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: "Urbanist",
    fontSize: "40px",
    fontWeight: "600",
    marginBottom: "24px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
    },
  },
}));

const Title = ({ text, color, align, width }) => {
  const { classes } = useStyles();
  return (
    <Typography
      className={classes.title}
      sx={{
        color: color,
        textAlign: align,
        width: width,
      }}
    >
      {text}
    </Typography>
  );
};

export default Title;
