import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import GetHiredWithUs from "./GetHiredWithUs";
import CurrentOpenings from "./CurrentOpenings";
import LetsGetInTouch from "../ReusableComponents/LetsGetInTouch";
import Banner from "../ReusableComponents/Banner";
import "../Styles/index.css";
import { useEffect } from "react";

const useStyles = makeStyles()((theme) => ({
  main: {
    [theme.breakpoints.up("md")]: {
      marginTop: "70px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
}));

const Careers = () => {
  const { classes } = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box className={classes.main}>
      <Box className={classes.titleContainer}>
        <div className="container">
          <h1 className="mainHeading">
            Searching For Talented People to <br /> Join Our Team
          </h1>
        </div>
        {/* <MainHeading
          text="Searching For
          Talented People to Join Our Team."
          background="linear-gradient(134deg, #151942 0%, #F7B66C 100%)"
          width="60%"
        /> */}
      </Box>
      <Banner
        background="linear-gradient(180deg, #FFF 0%, #4E95FF 100%)"
        text="Flexibility , Culture & Environment at One  Place"
        imageMobile="./Careers/Careers Banner.jpg"
        image="./Careers/Careers Banner.jpg"
        leftBackground="#000"
      />
      <Box>
        <GetHiredWithUs />
        <CurrentOpenings />
        <LetsGetInTouch />
      </Box>
    </Box>
  );
};

export default Careers;
