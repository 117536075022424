import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import EastIcon from "@mui/icons-material/East";

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "linear-gradient(180deg, #1C1D46 0%, #201E41 100%)",
    height: "500px",
    borderRadius: "24px",
    marginTop: "40px",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "80%",
      marginTop: "20px",
      marginBottom:'20px'
    },
    [theme.breakpoints.only("sm")]: {
      width: "80%",
      height: "100%",
      // margin:'40px'
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
      height: "100%",
      padding: "40px",
    },
  },
  leftContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "50%",
    gap: "16px",
    paddingLeft: "60px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
      width: " 100%",
      padding: "0px 40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "24px",
    },
  },
  title: {
    fontFamily: "Urbanist",
    fontSize: "32px",
    fontWeight: "600",
    color: "#FFFFFF",
    [theme.breakpoints.only("md")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
      marginTop: "40px",
    },
  },
  description: {
    fontFamily: "Urbanist",
    fontSize: "18px",
    color: "#F7F7F7",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "14px",
    },
  },
  getInDetailButton: {
    fontFamily: "Urbanist",
    textTransform: "none",
    color: "#000000",
    background: "#FFFFFF",
    padding: "8px 20px",
    borderRadius: "20px",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  rightContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px",
      width: "50%",
      marginTop: "40px",
    },
    [theme.breakpoints.only("md")]: {
      marginBottom: "20px",
      width: "40%",
    },
  },
}));

const EsignatureComponent = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.leftContainer}>
        <Typography className={classes.title}>eSignature</Typography>
        <Typography className={classes.description}>
          Reaching agreement among the parties digitally for any legal and
          lawful transaction. It offer a convenient and secure way to sign
          documents. This enhance search capabilities within a document
          repository, making it easier to find specific information.
        </Typography>
        {/* <Button
          endIcon={<EastIcon />}
          disableTouchRipple
          className={classes.getInDetailButton}
        >
          Get in Detail
        </Button> */}
      </Box>
      <Box className={classes.rightContainer}>
        <img
          src="./Home/E-Signature.svg"
          alt="skdhfa"
          style={{ height: "80%", width: "100%" }}
        />
      </Box>
    </Box>
  );
};

export default EsignatureComponent;
