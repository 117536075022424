import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles()((theme) => ({
  appbar: {
    background: "#FFF",
    boxShadow: "none",
  },
  closeButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "24px",
  },
}));

// const drawerWidth = 240;
const navItems = [
  {
    name: "home",
    path: "/",
  },
  {
    name: "about",
    path: "about",
  },
  {
    name: "services",
    path: "services/application-integration",
  },
  {
    name: "careers",
    path: "careers",
  },
  {
    name: "blogs",
    path: "blogs",
  },
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              component={Link}
              to={item.name === "home" ? "/" : `/${item.path}`}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  width: "100%",
                  fontSize: "24px",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  fontFamily: "Urbanist",
                }}
              >
                {item.name}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const { classes } = useStyles();
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" className={classes.appbar}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              src="./Home/Website Logo.svg"
              alt=""
              style={{ width: "100px" }}
            />
          </Link>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon sx={{ color: "#0069EC", fontSize: "40px" }} />
          </IconButton>
          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            {navItems.map((item, index) => (
              <Button key={index} sx={{ color: "#fff" }}>
                {item.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },

            height: "50%",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
              background: "#05021cb2",
              paddingTop: "24px",
            },
          }}
        >
          <Button
            disableTouchRipple
            onClick={handleDrawerToggle}
            className={classes.closeButton}
          >
            <CloseIcon sx={{ color: "#FFFFFF", fontSize: "40px" }} />
          </Button>
          {drawer}
        </Drawer>
      </nav>
      <Toolbar />
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
