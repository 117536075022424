export const keyDriveElements = {
  imagePath: "./Service/KeyDrives/Data Management Keydrive.svg",
  keyDrivers: [
    "Decision Support",
    "Increased Costs ",
    "Strategic Planning and Forecast",
    "Regulatory Compliance",
    "Operational Efficiency",
    "Competitive Advantage",
  ],
  path: "data-management",
};

export const benefits = {
  benefitElements: [
    "Decisive in Business Expansion",
    "Efficient Data Management",
    "Faster Time to Market",
    "Scalability and Flexibility",
    "Cost Savings",
    "Business Intelligence and Reporting",
  ],
  image: "./Service/Benefits/Daga Management Benefit.svg",
};

export const content = {
  image: "./Service/Banners/data management banner.jpg",
  text: "All Data Meet at One Stop in a Digital Journey",
  mainHeading:
    "Empowering Businesses with Cutting-Edge Data Management Solutions",
  textBackground: "linear-gradient(180deg, #FFF 0%, #3986E5 100%)",
  leftBackground: "linear-gradient(180deg, #000 0%, #153859 100%)",
};

export const about = {
  firstAbout: {
    serviceName: "Enterprise Data Management",
    description: `Specialized platforms or systems designed to efficiently collect, store, manage, and analyse large volumes of structured and unstructured data from various sources within an organization. These solutions serve as a central repository for data, making it easier for businesses to access and analyze information for reporting, business intelligence, and decision-making purposes.`,
  },
  secondAbout: {
    serviceName: "Capabilities",
    description: `At VassuTech, we specialize in data migration and offer ETL-based solutions for the seamless transfer of data from one platform to another. We have extensive experience in building data warehouses and data lakes, enabling businesses to analyze their data and gain insights into their operations`,
  },
};
