import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import EastIcon from "@mui/icons-material/East";
import Bar from "../../../ReusableComponents/Bar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import CallMadeIcon from "@mui/icons-material/CallMade";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 100px",
    [theme.breakpoints.down("md")]: {
      padding: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px",
    },
  },
  tipContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
    },
  },

  findOutButton: {
    background: "linear-gradient(180deg, #0069EC 0%, #3986E5 100%)",
    padding: "10px 20px",
    color: "#fff",
    fontFamily: "Urbanist",
    fontSize: "15px",
    textTransform: "none",
    borderRadius: "25px",
    marginTop: "15px",
    "&:hover": {
      background: "linear-gradient(180deg, #0069EC 0%, #3986E5 100%)",
    },
  },
  title: {
    color: "#000000",
    fontFamily: "Urbanist",
    fontSize: "37px",
    fontWeight: "600",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0px",
      fontSize: "26px",
    },
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "30px",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "28px",
      width: "100%",
    },
  },
  card: {
    width: "31%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "30px",
      // borderBottom: "1px solid #6E6E6E",
      // paddingBottom: "10px",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
  },
  cardDescription: {
    color: "#5F6D7E",
    fontFamily: "Urbanist",
    fontSize: "16px",
    marginTop: "10px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
  },
  cardTitle: {
    fontWeight: "600",
    color: `#000000`,
    fontFamily: "Urbanist",
    fontSize: "18px",
    marginTop: "10px",
    [theme.breakpoints.only("sm")]: {
      fontSize: "22px",
    },
  },
  auther: {
    fontSize: "16px",
    fontFamily: "Urbanist",
    marginTop: "15px",
    width: "100%",
    textAlign: "right",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "24px",
  },

  cardImage: {
    width: "100%",
    borderRadius: "16px",
  },
  cardsMainContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "cneter",
      width: "90%",
      flexDirection: "column",
    },
    [theme.breakpoints.only("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "cneter",
      width: "90%",
    },
  },
  moreButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "cneter",
    width: "100%",
  },
  moreButton: {
    textTransform: "none",
    fontFamily: "Urbanist",
    padding: "0px",
  },
  blogsButton: {
    background: "linear-gradient(180deg, #0069EC 0%, #3986E5 100%)",
    color: "#fff",
    fontFamily: "Urbanist",
    textTransform: "none",
    borderRadius: "30px",
    padding: "12px 24px",
    "&: hover": {
      background: "linear-gradient(180deg, #0069EC 0%, #3986E5 100%)",
    },
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "24px",
    width: "200px",
    marginRight: "95px",

    [theme.breakpoints.only("md")]: {
      marginRight: "95px",
      width: "200px",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
    },
  },
}));

const cardDetails_test = [
  {
    image: "./Blogs/MBA.svg",
    date: "25 Apr 2022 ",
    description:
      "The forthcoming MBA – Starting from Oct 15 to 18- is expected to provide opportunity and explore the digital ideas and solutions that may further help is reducing processing costs, increases transparency in the Real Estate industry",
    title: "MBA, Oct 2023",
  },
];

const OurBlogsAndArticles = () => {
  const { classes } = useStyles();

  let [cardDetails, setCardDetails] = useState([]);

  useEffect(() => {
    fetch("data/blogs.json")
      .then((r) => r.json())
      .then((data) => setCardDetails(data));
  }, []);

  const cardDetails_text = JSON.stringify(cardDetails[0]);
  const json_obj = cardDetails[0];

  //const cardDetailsForMobile = cardDetails_test[0];
  const cardDetailsForSmallDevice = cardDetails_test.slice(0);

  return (
    <Box className={classes.container}>
      <Box className={classes.tipContainer}>
        <Box className={classes.titleContainer}>
          <Bar backgroundColor="#0069EC" />
          <Typography className={classes.title}>
            Our Blogs & Articles
          </Typography>
        </Box>
      </Box>

      <Box className={classes.cardsMainContainer}>
        <Box className={classes.cardContainer}>
          {cardDetails.map((card, index) => (
            <Box
              key={index}
              className={classes.card}
              sx={{ display: { xs: "none", lg: "block" } }}
            >
              <img className={classes.cardImage} src={card.image} alt={index} />
              <Typography className={classes.cardTitle}>
                {card.title}
              </Typography>
              <Typography className={classes.cardDescription}>
                {card.description}
              </Typography>
              <Typography className={classes.auther}>{card.source}</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Button
                  component={Link}
                  to="/blogs"
                  className={classes.moreButton}
                >
                  Learn More...
                </Button>
              </Box>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          {cardDetails.map((card, index) => (
            <Box className={classes.card} key={index}>
              <img src={card.image} alt="alsdf" style={{ width: "100%" }} />
              <Typography className={classes.cardTitle}>
                {card.title}
              </Typography>
              <Typography className={classes.cardDescription}>
                {card.description}
              </Typography>
              <Button
                component={Link}
                to="/blogs"
                className={classes.moreButton}
              >
                Learn More...
              </Button>
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{ display: { xs: "none", md: "block", lg: "none" } }}>
        <Box className={classes.cardContainer}>
          {cardDetails.map((card, index) => (
            <Box
              key={index}
              className={classes.card}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <img src={card.image} alt={index} style={{ width: "100%" }} />
              <Typography className={classes.cardTitle}>
                {card.title}
              </Typography>
              <Typography className={classes.date}>
                {card.description}
              </Typography>
              <Button
                component={Link}
                to="/blogs"
                className={classes.moreButton}
              >
                Learn More...
              </Button>
            </Box>
          ))}
        </Box>
      </Box>

      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Button
          component={Link}
          to="/blogs/"
          disableTouchRipple
          onClick={() => window.scrollTo(0, 0)}
          className={classes.blogsButton}
        >
          More Blogs
          <CallMadeIcon sx={{ fontSize: "15px", marginLeft: "5px" }} />
        </Button>
      </Box> */}
    </Box>
  );
};

export default OurBlogsAndArticles;
