import { Box, Button, Dialog, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Bar from "../../ReusableComponents/Bar";
import Title from "../../ReusableComponents/Title";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { cardDetails } from "./BlogElements";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 100px",
    [theme.breakpoints.down("md")]: {
      padding: "40px 24px",
    },
  },
  cardDate: {
    color: "#6941C6",
    fontFamily: "Urbanist",
    fontSize: "14px",
  },
  cardTitle: {
    fontWeight: "600",
    color: `#000000`,
    fontFamily: "Urbanist",
    fontSize: "18px",
    [theme.breakpoints.only("sm")]: {
      fontSize: "22px",
    },
  },
  cardDescription: {
    color: "#5F6D7E",
    fontFamily: "Urbanist",
    fontSize: "16px",
    // marginTop: "-12px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
  },
  cardImage: {
    width: "100%",
    borderRadius: "16px",
  },
  cardTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    height: "60px",
    marginTop: "16px",
    [theme.breakpoints.only("xs")]: {
      height: "100%",
      marginTop: "0px",
    },
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "10%",
    },
  },
  cardContainer: {
    width: "31.5%",
    marginBottom: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "0px",
    [theme.breakpoints.down("lg")]: {
      height: "350px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      gap: "16px",
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "48%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      height: "100%",
    },
    cursor: "pointer",
  },

  titleContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flexDirection: "column",
    },
  },
}));

const BlogsSection = () => {
  const [cardDetails1, setBlogDetails] = useState([]);

  useEffect(() => {
    fetch("data/blogs.json")
      .then((r) => r.json())
      .then((data) => setBlogDetails(data));
  }, []);

  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#13135F" />
        <Title text="Blogs & Stories" color="@13135F" />
      </Box>

      <Box className={classes.cardsContainer}>
        {cardDetails.map((eachCard, index) => (
          <Box key={index} className={classes.cardContainer}>
            <img
              className={classes.cardImage}
              src={eachCard.image}
              alt={eachCard.title}
            />

            <Box className={classes.cardTitleContainer}>
              <Typography className={classes.cardTitle}>
                {eachCard.title}
              </Typography>
              <ArrowOutwardIcon sx={{ fontSize: "15px", marginTop: "8px" }} />
            </Box>
            <Typography className={classes.cardDescription}>
              {eachCard.body}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BlogsSection;
