export const keyDriveElements = {
  imagePath: "./Service/KeyDrives/Data Security Keydrive.svg",
  keyDrivers: [
    "Financial Data Breach",
    "Auditable and Accountable",
    "Legal Liability",
    "Personal Data Privacy",
    "Competitive Advantage",
    "Business Impact",
  ],
  path: "data-security",
};

export const benefits = {
  benefitElements: [
    "Data protection & Privacy",
    "Avoid Legal Implications",
    "Eliminate Business Discontinuity",
    "Compliance to Regulatory Norms",
    "Saving IT Maintenance Costs",
  ],
  image: "./Service/Benefits/Dataecurity Benefit.svg",
};
export const content = {
  image: "./Service/Banners/data security banner.jpg",
  text: "Data Security is Key Ingredient in a Digital Product",
  mainHeading:
    "Revolutionize the Protection and Privacy of Sensitive Information with Protigrity ",
  textBackground: "linear-gradient(180deg, #FFF 0%, #D9EF82 100%)",
  leftBackground: "linear-gradient(136deg, #000 0%, #171D00 100%)",
};

export const about = {
  firstAbout: {
    serviceName: "Data Security",
    description: `Data security is important ingredient of any digital solution to protect the confidential data from unauthorized access or being disclosed. There are many legalisations in different countries that are aimed to safeguard the interests of consumers or end user data such as PII data. Failing to take care of protect will liable for penalties and damage to reputation. Architecting any digital solution following data security practices eliminates the risks of data theft.`,
  },
  secondAbout: {
    serviceName: "Capabilities",
    description: `At VassuTech, we specialize in data migration and offer ETL-based solutions for the seamless transfer of data from one platform to another. We have extensive experience in building data warehouses and data lakes, enabling businesses to analyze their data and gain insights into their operations`,
  },
};
