import { Box } from "@mui/material";
import React from "react";
// import OurProjects from "../OurProjects";
// import Benefits from "../Elements/Benefit";
import Benefits from "../BenefitItems";
import KeyDrivers from "../Elements/Key/Key";
import ButtonsComponent from "../Elements/Buttons/ButtonsComponent";
import { makeStyles } from "tss-react/mui";
import { keyDriveElements, benefits, content, about } from "./Elements";
import "../../Styles/index.css";
import Banner from "../../ReusableComponents/Banner";
import About from "../../ReusableComponents/About";

const useStyles = makeStyles()((theme) => ({
  aboutContainer: {
    padding: "64px 100px",
    paddingBottom: "20px",
    [theme.breakpoints.only("xs")]: {
      padding: "24px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px",
    },
  },
}));

const DataManagement = () => {
  const { classes } = useStyles();
  return (
    <Box mt={8}>
      <ButtonsComponent />
      <Box>
        <Box className={classes.titleContainer}>
          <div className="container">
            <h1 className="mainHeading">{content.mainHeading}</h1>
          </div>
          <Banner
            background="none"
            text={content.text}
            imageMobile={content.image}
            image={content.image}
            leftBackground={content.leftBackground}
          />
          <Box className={classes.aboutContainer}>
            <About
              title={about.firstAbout.serviceName}
              description={about.firstAbout.description}
              width="280px"
            />
            <About
              title={about.secondAbout.serviceName}
              description={about.secondAbout.description}
              width="280px"
            />
          </Box>
        </Box>
      </Box>
      <KeyDrivers activeKeyDriver={keyDriveElements} />
      <Benefits activeBenefits={benefits} />
      {/* <OurProjects /> */}
    </Box>
  );
};

export default DataManagement;
