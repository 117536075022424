export const keyDriveElements = {
  imagePath: "./Service/KeyDrives/Quality Assurance Keydrive.svg",
  keyDrivers: [
    "Increase of Costs",
    "Protecting Brand Image",
    "Faster Process",
    "Regulatory and Legal Compliance",
    "Operational Efficiency",
    "Competitive Advantage",
  ],
  path: "quality-assurance",
};

export const benefits = {
  benefitElements: [
    "Improved Product Quality",
    "Reduced Time-to-Market",
    "Enhanced Customer Satisfaction",
    "Avoid Replacement or Maintenance Costs",
    "Reputation and Brand Marketing",
  ],
  image: "./Service/Benefits/Quality Assurance Benefit.svg",
};

export const content = {
  image: "./Service/Banners/Quality banner.jpg",
  text: "The Outcome greets the Desired Expectations",
  mainHeading:
    "Quality Assurance Masters Automation & Manual Testing for Unrivaled Software Excellence",
  textBackground: "linear-gradient(180deg, #C1CBFF 0%, #3986E5 100%)",
  leftBackground: "#000",
};

export const about = {
  firstAbout: {
    serviceName: "Quality Assurance",
    description: `Quality assurance activities should be aligned with the development lifecycle, ensuring that defects are identified and resolved early in the process. Continuous improvement, knowledge sharing, and staying up to date with industry best practices are also crucial for effective quality assurance in both automation and manual testing.`,
  },
  secondAbout: {
    serviceName: "Capabilities",
    description: `At VassuTech, we have well trained team of Quality Assurance, who execute both manual and automated testing adopting best QA practices, measuring against KPI, controlled by Quality Audit checklist and guidelines. Automation scripts are developed using Java or Python and leveraging different automation frameworks evaluated or customized framework, wherever applicable. VassuTech’ s skill pool with intense QA and testing expertise on various facets of testing have exposure to various testing environments, methods and tools and professionally work in tandem with third-party Agile DevOps teams ensuring proper inputs are given to the development.`,
  },
};
