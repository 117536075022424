import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";
import { motion } from "framer-motion";
import "../../../Styles/index.css";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "100px 100px",
    [theme.breakpoints.only("md")]: {
      padding: "80px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "24px",
      marginBottom: "40px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "55px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "55px",
      flexDirection: "column",
      width: "70%",
    },
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
  },
  leftContainer: {
    width: "36%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  rightContainer: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "90%",
      order: "-1",
      marginTop: "20px",
    },
  },
  description: {
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "18px",
    margin: "20px 0px",
    width: "100%",
    textAlign: "justify",
    lineHeight: "30px",
    fontWeight: "400",
    marginRight: "10px",
  },
  trustedContainer: {
    background: "#0069EC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "16px 24px",
    borderRadius: "10px",
    marginTop: "-60px",
    position: "absolute",
    boxSizing: "border-box",
    width: "250px",
    marginLeft: "-50px",
    [theme.breakpoints.only("xs")]: {
      width: "180px",
      marginTop: "-40px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "auto",
      marginTop: "-60px",
      fontSize: "32px",
      height: "100px",
      marginLeft: "-100px",
    },
    [theme.breakpoints.only("md")]: {
      marginLeft: "-35px",
      marginTop: "-40px",
    },
  },
  trustedText: {
    color: "#fff",
    fontFamily: "Urbanist",
    fontSize: "24px",
    fontWeight: "800",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      textAlign: "justify",
    },
  },
}));

const Innovations = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box sx={{ display: { lg: "none", xs: "block" } }}>
        <Box className={classes.titleContainer}>
          <Bar backgroundColor="#ffc526" />
          <Title text="Innovation" color="#05021C" />
        </Box>
      </Box>
      <Box className={classes.mainContainer}>
        <Box className={classes.leftContainer}>
          <Box sx={{ display: { lg: "block", xs: "none" } }}>
            <Box className={classes.titleContainer}>
              <Bar backgroundColor="#ffc526" />
              <Title text="Innovation" color="#05021C" />
            </Box>
          </Box>
          <motion.h4
            variants={{
              initial: {
                opacity: 0,
                x: -150,
              },
              animate: () => ({
                opacity: 1,
                x: 0,
                transition: {
                  delay: 0.5,
                  duration: 1,
                },
              }),
            }}
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true,
            }}
            transition={{ duration: 2 }}
            className="innovationDescription"
          >
            At VassuTech, our ideas turned into innovation in transforming the
            business process into Product Development in the IT landscape of an
            enterprise. Our expertise acquired and knowledge gained made inroads
            into software product development to address the existing challenges
            of industry in executing the business process and operations.
          </motion.h4>
        </Box>
        <Box className={classes.rightContainer}>
          <img
            src="./Home/Innovation.svg"
            alt="laksd"
            style={{
              position: "relative",
              width: "100%",
              borderRadius: "16px",
            }}
          />
          <Box>
            <Box className={classes.trustedContainer}>
              <Typography className={classes.trustedText}>
                Trusted by All Our Partners
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Innovations;
