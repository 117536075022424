import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { motion } from "framer-motion";

const useStyles = makeStyles()(() => ({
  leftContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: "linear-gradient(to right, #05021C , #111F86)",
  },
  text: {
    fontSize: "48px",
    fontFamily: "Urbanist",
    fontWeight: "700",
    color: "#FFFFFF",
    background: "linear-gradient(180deg, #FFF 0%, #4E95FF 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    width: "65%",
  },
}));

const animationVariants = {
  initial: {
    opacity: 0,
    x: -300,
  },
  animate: () => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.05,
      duration: 1,
    },
  }),
};

const Banner = ({ text, image, imageMobile, leftBackaground }) => {
  const { classes } = useStyles();
  return (
    <Grid
      container
      sx={{
        height: { md: "100vh", xs: "60vh" },
        overflow: "hidden",
      }}
    >
      <Grid
        item
        xs={0}
        md={6}
        sx={{ height: "100vh", display: { xs: "none", md: "block" } }}
      >
        <Box
          className={classes.leftContainer}
          sx={{ background: leftBackaground }}
        >
          <motion.h1
            variants={animationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            style={{
              color: "#FFFFFF",
              fontSize: "54px",
              fontFamily: "Urbanist",
              fontWeight: "700",
              width: "70%",
              background: "linear-gradient(180deg, #FFF 0%, #4E95FF 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {text}
          </motion.h1>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box height="100%" display={{ display: { md: "block", xs: "none" } }}>
          <img src={imageMobile} alt="banner" style={{ width: "100%" }} />
        </Box>

        {/* banner for small devices */}

        <Box display={{ display: { md: "none", xs: "block" } }}>
          <img
            src={image}
            alt="banner"
            style={{ width: "100%", height: "120%" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Banner;
