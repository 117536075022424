import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import "./App.css";
import AboutUs from "./Components/AboutUs";
import Careers from "./Components/Careers";
import Blogs from "./Components/Blogs";
import Footer from "./Components/Footer";
import DrawerAppBar from "./Components/ReusableComponents/Appbar";
import { Box } from "@mui/material";
import DescktopHeader from "./Components/Header/Header";
import Blackchain from "./Components/Services/Blackchain";
import ApplicationIntegration from "./Components/Services/ApplicationIntegration";
import DigitalSolutions from "./Components/Services/DigitalSolutions";
import DataManagement from "./Components/Services/DataManagement";
import DataSecurity from "./Components/Services/DataSecurity";
import BiandAiMl from "./Components/Services/BiandAiMl";
import QualityAssurance from "./Components/Services/QualityAssurance";
import CloudSolutions from "./Components/Services/CloudSolutions";

const App = () => {
  return (
    <Router>
      <div>
        <Box
          sx={{
            display: { xs: "none", lg: "block" },
            position: "fixed",
            width: "100%",
          }}
        >
          <DescktopHeader />
        </Box>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <DrawerAppBar />
        </Box>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          {/* <Route path="/services" element={<Services />} /> */}
          <Route path="/careers" element={<Careers />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route
            path="/services/blockchain-solutions"
            element={<Blackchain />}
          />
          <Route
            path="/services/application-integration"
            element={<ApplicationIntegration />}
          />
          <Route
            path="/services/digital-solutions"
            element={<DigitalSolutions />}
          />
          <Route
            path="/services/data-management"
            element={<DataManagement />}
          />
          <Route path="/services/data-security" element={<DataSecurity />} />
          <Route
            path="/services/quality-assurance"
            element={<QualityAssurance />}
          />
          <Route path="/services/bi-ai-ml" element={<BiandAiMl />} />
          <Route
            path="/services/cloud-solutions"
            element={<CloudSolutions />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
