import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Bar from "../../../ReusableComponents/Bar";
import Title from "../../../ReusableComponents/Title";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: "64px 100px",
    [theme.breakpoints.down("md")]: {
      padding: "60px 25px",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  mapContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
    width: "100%",
  },
}));

const OurPresence = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Bar backgroundColor="#0F143B" />
        <Title text="Our Presence" color="#0F143B" align="center" />
      </Box>
      <Box className={classes.mapContainer}>
        <img
          src="./About/Our Presence.svg"
          alt="map"
          style={{ width: "100%", position: "relative" }}
        />
      </Box>
    </Box>
  );
};

export default OurPresence;
